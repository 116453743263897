import { Card, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { adminApi } from '../../api/apiClient';
import { notifyError } from '../../utils/notification';
import AdminUserCreateConfirm from './AdminUserCreateConfirm';
import AdminUserForm from './AdminUserForm';

type AdminUserFormType = {
  name: string;
  email: string;
};

const AdminUserCreate = () => {
  const history = useHistory();
  const [form] = useForm<AdminUserFormType>();

  const initialValue = {
    name: '',
    email: '',
  };

  const createUser = useCallback(
    (form: AdminUserFormType) => {
      Modal.confirm({
        icon: null,
        content: <AdminUserCreateConfirm formContent={form} />,
        okText: 'はい',
        cancelText: 'キャンセル',
        width: 800,
        onOk: async () => {
          try {
            const { data } = await adminApi.adminUserControllerCreateAdminUser({
              name: form.name,
              email: form.email,
            });
            Modal.info({
              title: 'ユーザー作成に成功しました',
              content: `パスワードは${data.password}になります。`,
            });
            history.push('/users/');
          } catch {
            notifyError('ユーザー作成に失敗しました。');
          }
        },
      });
    },
    [history]
  );

  return (
    <Container>
      <Heading>
        <PageTitle>管理ユーザー新規作成</PageTitle>
      </Heading>
      <Content>
        <Card>
          <AdminUserForm form={form} initialValue={initialValue} onFinish={createUser} />
        </Card>
      </Content>
    </Container>
  );
};

export default AdminUserCreate;

const Container = styled.div`
  width: 80%;
  padding: 24px;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #5a89d0;
  padding-right: 40px;
`;

const PageTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: #5a89d0;
`;

const Content = styled.div`
  width: 90%;
  margin-left: 24px;
  margin-top: 36px;
`;
