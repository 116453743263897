import { format } from 'date-fns';
import { useCallback } from 'react';

import companyPlansDataLoader from '../../api/companyPlansDataLoader';
import CsvDownloadButton from '../../components/Button/CsvDownloadButton';
import { Company, Plan } from '../../generated-api';
import { Paths } from '../../types/Paths';
import isNonNull from '../../utils/isNonNull';
import toMap from '../../utils/toMap';

type Columns = {
  company_name: string;
  company_rpa_id: string;
  plan_name: string;
  since: string;
};

const csvHeader: Record<string, Paths<Columns>> = {
  会社名: ['company_name'],
  顧客番号: ['company_rpa_id'],
  料金プラン: ['plan_name'],
  プラン開始日: ['since'],
};

interface Props {
  companies: Company[];
  plans: Plan[];
}

export default function DownloadCompanyPlans(props: Props) {
  const { companies, plans } = props;

  const getRows = useCallback(async (): Promise<Columns[]> => {
    const companyPlans = (
      await companyPlansDataLoader.getCompanyPlansByIds(companies.map((company) => company.company_id))
    ).filter(isNonNull);
    const companyMap = toMap(companies, 'company_id');
    const planMap = toMap(plans, 'plan_id');
    return (
      companyPlans?.map((item) => {
        const companyName = companyMap.get(item.company_id)?.company_name ?? '-';
        const companyRpaId = companyMap.get(item.company_id)?.company_rpa_id ?? '-';
        const planName = planMap.get(item.plan_id)?.plan_name ?? '-';
        return {
          company_name: companyName,
          company_rpa_id: companyRpaId,
          plan_name: planName,
          since: item.since,
        };
      }) ?? []
    );
  }, [companies, plans]);

  const fileName = `company-plan_${format(new Date(), 'yyyy-MM-dd')}.csv`;
  return <CsvDownloadButton columns={csvHeader} fileName={fileName} getRows={getRows} />;
}
