import { Select, Space } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { add, format, parseISO } from 'date-fns';
import { eachMonthOfInterval } from 'date-fns/esm';
import { useCallback, useState } from 'react';

import PageLayout from '../../components/Container/PageLayout';
import DownloadUsageAtMonth from './DownloadUsageAtMonth';

const selectForm = {
  width: '18rem',
};

const UsageOutputPage = () => {
  const [selectedYearMonth, setSelectedYearMonth] = useState<string | undefined>(undefined);

  const start = parseISO('2021-08'); // サービス開始年月
  const end = add(new Date(), { months: -1 }); // 利用料金確定済み年月

  const selectableYearMonth = eachMonthOfInterval({ start: start, end: end }).reverse();

  const select = useCallback((value: SelectValue) => {
    setSelectedYearMonth(value?.toString());
  }, []);

  return (
    <PageLayout heading="年月別利用量出力">
      <Space>
        <label>年月</label>
        {/* Selectをstyled-componentでラップすると何故かonChangeで型エラーが生じるためstyle propsで対応 */}
        <Select placeholder="出力したい年月を選択してください" style={selectForm} onChange={select}>
          {selectableYearMonth.map((yearMonth) => {
            const formatedMonth = format(yearMonth, 'yyyy-MM');
            return <Select.Option key={formatedMonth}>{formatedMonth}</Select.Option>;
          })}
        </Select>
        <DownloadUsageAtMonth disabled={!selectedYearMonth} month={selectedYearMonth ?? ''} />
      </Space>
    </PageLayout>
  );
};

export default UsageOutputPage;
