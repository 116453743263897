import { Alert, Button, Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import useForm from 'antd/lib/form/hooks/useForm';
import { useCallback, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { authApi } from '../../api/apiClient';
import AuthPageContainer from '../../components/Container/AuthPageContainer';
import { useAuthStore, useCommitLogin } from '../../store/useAuthStore';
import { useFetchMe } from '../../store/useMeStore';

type FormValues = {
  email: string;
  password: string;
};

const mailFormRules: Rule[] = [
  { required: true, message: 'この項目は必須です' },
  { type: 'email', message: '有効なメールアドレスを入力してください' },
];
const passwordFormRules: Rule[] = [{ required: true, message: 'この項目は必須です' }];
const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? '';

const LoginPage = () => {
  const commitLogin = useCommitLogin();
  const [form] = useForm();
  const history = useHistory();
  const fetchMe = useFetchMe();
  const [, isAuthorized] = useAuthStore();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginDisabled, setLoginDisabled] = useState(true);

  const enableLogin = useCallback((value) => {
    if (value) {
      setLoginDisabled(false);
    } else {
      setLoginDisabled(true);
    }
  }, []);

  const login = useCallback(
    async ({ email, password }) => {
      try {
        setIsLoading(true);
        const token = recaptchaRef.current?.getValue() ?? '';
        recaptchaRef.current?.reset();
        const { data } = await authApi.authControllerLogin({
          email,
          password,
          token,
        });
        commitLogin(data.access_token);
        await fetchMe();
        history.push('/');
      } catch (e) {
        console.log(e);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [commitLogin, fetchMe, history]
  );

  return isAuthorized ? (
    <Redirect to="/" />
  ) : (
    <AuthPageContainer>
      <Form<FormValues> form={form} layout="vertical" requiredMark={false} onFinish={login}>
        {hasError && <Alert message="メールアドレスまたはパスワードが違います。" type="error" />}
        <FormItem label="メールアドレス" name="email" rules={mailFormRules}>
          <FormInput type="email" />
        </FormItem>
        <FormItem label="パスワード" name="password" rules={passwordFormRules}>
          <FormInput type="password" />
        </FormItem>
        <FormItem>
          <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} onChange={enableLogin}></ReCAPTCHA>
        </FormItem>
        <FormItem>
          <LoginButton block disabled={loginDisabled} htmlType="submit" loading={isLoading} type="primary">
            ログイン
          </LoginButton>
        </FormItem>
      </Form>
      <PasswordForgetLink to="/password/forget">パスワードを忘れた場合</PasswordForgetLink>
    </AuthPageContainer>
  );
};

export default LoginPage;

const FormItem = styled(Form.Item)`
  width: 500px;
`;

const FormInput = styled(Input)`
  height: 45px;
`;

const LoginButton = styled(Button)`
  height: 50px;
`;

const PasswordForgetLink = styled(Link)`
  width: 500px;
`;
