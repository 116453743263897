import companyPlansDataLoader from '../../api/companyPlansDataLoader';
import usersDataLoader from '../../api/usersDataLoader';
import LoadingDot from '../../components/Loading/LoadingDot';
import { Plan } from '../../generated-api';
import useAPIData from '../../hooks/useAPIData';

type UserNameProps = {
  userId: string;
};

export function CompanyColumnUserName({ userId }: UserNameProps) {
  const { data, loading } = useAPIData(async () => {
    return await usersDataLoader.getUserById(userId);
  });

  return <div style={{ minWidth: '100px' }}>{loading ? <LoadingDot /> : data?.name ?? ''}</div>;
}

type PlanNameProps = {
  companyId: string;
  plans: Plan[] | null;
};

export function CompanyColumnPlanName({ companyId, plans }: PlanNameProps) {
  const { data, loading } = useAPIData(async () => {
    return await companyPlansDataLoader.getCompanyPlanById(companyId);
  });

  const plan = plans?.find((plan) => plan.plan_id === data?.plan_id);

  return <div style={{ minWidth: '100px' }}>{loading ? <LoadingDot /> : plan?.plan_name ?? ''}</div>;
}
