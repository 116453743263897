import { ReactNode } from 'react';
import styled from 'styled-components';

type Variant = 'success' | 'danger';
interface Props {
  variant: Variant;
  children: ReactNode;
}
const Badge = ({ children, variant }: Props) => {
  return <BorderBox variant={variant}>{children}</BorderBox>;
};

const colors: Record<Variant, string> = {
  success: '#73d13d',
  danger: '#ff4d4f',
};

const BorderBox = styled.span<Props>`
  border-width: 1px;
  border-style: solid;
  padding: 4px;
  color: ${({ variant }) => colors[variant]};
`;

export default Badge;
