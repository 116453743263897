import { Card } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import styled from 'styled-components';

import { adminApi } from '../../api/apiClient';
import { AdminUser } from '../../generated-api';
import { notifyError } from '../../utils/notification';
import AdminUserForm from './AdminUserForm';

type AdminUserFormType = {
  name: string;
  email: string;
};

type param = {
  id: string;
};

const AdminUserUpdate = () => {
  const history = useHistory();
  const param: param = useParams();
  const [form] = useForm<AdminUserFormType>();

  const [adminUser, setAdminUser] = useState<AdminUser | null>(null);
  useAsync(async () => {
    const { data } = await adminApi.adminUserControllerGetAdminUser(param.id);
    setAdminUser(data);
  }, [param]);

  const updateUser = useCallback(
    (form: AdminUserFormType) => {
      try {
        adminApi.adminUserControllerEditAdminUser(param.id, {
          name: form.name,
          email: form.email,
        });
        history.push('/users/');
      } catch {
        notifyError('ユーザー編集に失敗しました。');
      }
    },
    [history, param]
  );

  return (
    <Container>
      <Heading>
        <PageTitle>管理ユーザー編集</PageTitle>
      </Heading>
      <Content>
        <Card>
          <AdminUserForm form={form} initialValue={adminUser} onFinish={updateUser} />
        </Card>
      </Content>
    </Container>
  );
};

export default AdminUserUpdate;

const Container = styled.div`
  width: 80%;
  padding: 24px;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #5a89d0;
  padding-right: 40px;
`;

const PageTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: #5a89d0;
`;

const Content = styled.div`
  width: 90%;
  margin-left: 24px;
  margin-top: 36px;
`;
