import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { adminApi } from '../api/apiClient';

type MeState = {
  userId: string;
  userName: string;
  passwordChangeRequired: boolean;
};

const meState = atom<MeState>({
  key: 'MeStore/meState',
  default: {
    userId: '',
    userName: '',
    passwordChangeRequired: true,
  },
});

export const useMeStore = (): MeState => useRecoilValue(meState);

export const useFetchMe = (): (() => Promise<void>) => {
  const setMe = useSetRecoilState(meState);

  return useCallback(async () => {
    const { data } = await adminApi.adminUserControllerGetMe();
    setMe({
      userId: data.user_id,
      userName: data.name,
      passwordChangeRequired: data.password_change_required,
    });
  }, [setMe]);
};
