import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format, isThisMonth, parseISO } from 'date-fns';
import { useMemo } from 'react';

import { UsagePerUser } from '../../generated-api';

export type StatsUserTableData = {
  statsUser: Record<string, number>;
  month: string;
  confirmed: boolean; // 未確定ならfalse
};

type Props = {
  statisticsUser: UsagePerUser[] | undefined;
};

const StatsUserTable = ({ statisticsUser }: Props) => {
  const { dataSource, userNames } = useMemo(() => {
    const userMonthToData = new Map<string, Record<string, number>>();
    const userNamesSet = new Set<string>();
    for (const usage of statisticsUser ?? []) {
      const monthStat = userMonthToData.get(usage.month) ?? {};
      monthStat[usage.user_name] = usage.usage;
      userMonthToData.set(usage.month, monthStat);
      userNamesSet.add(usage.user_name);
    }
    return {
      userNames: [...userNamesSet],
      dataSource: [...userMonthToData.entries()].map(([month, usages]) => ({
        statsUser: usages,
        month,
        confirmed: isThisMonth(parseISO(month)) ? false : true,
      })),
    };
  }, [statisticsUser]);
  const children: ColumnsType<StatsUserTableData> = (userNames ?? []).map((element: string) => {
    return {
      title: element,
      dataIndex: element,
      render: (_, record) => {
        const userOfColumn = element;
        const value = record.statsUser[userOfColumn] ?? 0;
        return value.toLocaleString() + '枚';
      },
      width: 200,
      align: 'right',
    };
  });
  const userColumns: ColumnsType<StatsUserTableData> = [
    {
      title: 'ご利用月',
      dataIndex: 'month',
      render: (value, record) => {
        const formattedDate = format(parseISO(value), 'yyyy/M');
        if (record.confirmed) {
          return formattedDate;
        } else {
          return '（未確定）' + formattedDate;
        }
      },
      width: 300,
      align: 'right',
    },
    {
      title: '利用枚数',
      children: children,
    },
  ];

  return <Table bordered={true} columns={userColumns} dataSource={dataSource} pagination={false} rowKey="month" />;
};

export default StatsUserTable;
