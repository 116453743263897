import { Form, Input, InputNumber, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { ReactElement, ReactNode, useMemo } from 'react';
import { useList } from 'react-use';
import short from 'short-uuid';
import styled from 'styled-components';

import FormItemWithLabel from '../../../components/Form/FormItemWithLabel';
import { DocumentPaymentRecordEntity, Payment } from '../../../generated-api';
import renderTableColumn from './renderTableColumn';
import { OcrResultPageDefinition, ResultPageDocument } from './types';

const PaymentRecordDetailSection = styled.div`
  padding: 8px;
  margin-bottom: 24px;
  border: 1px solid #f0f0f0;
  display: grid;
  grid-gap: 8px;
  align-items: center;
  overflow: auto;
  grid-auto-rows: 40px;
`;
const PaymentRecordDetailLabel = styled.div`
  margin-bottom: 12px;
`;
const RowHeaderLabel = styled.div`
  font-weight: bold;
  padding-left: 4px;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(80px, 1fr)) repeat(2, minmax(100px, 1fr));
  grid-gap: 8px;
  align-items: baseline;
`;
const InputNumberStyle = styled(InputNumber)`
  width: 100%;
`;

type FormProps = {
  entity: DocumentPaymentRecordEntity;
};

function PaymentForm({ entity }: FormProps): ReactElement {
  const details = useMemo(() => {
    return (
      entity.payments?.reduce<Record<string, Payment>>((accumulator, current) => {
        accumulator[short.generate()] = current;
        return accumulator;
      }, {}) ?? {}
    );
  }, [entity.payments]);

  const [rowIds] = useList(Object.keys(details));

  const initialValue = useMemo(() => {
    return {
      classification: '',
      detail: '',
      payment_amount: 0,
      withholding_tax_amount: 0,
    };
  }, []);

  if (rowIds.length === 0) {
    const rowId = short.generate();
    rowIds.push(rowId);
    details[rowId] = initialValue;
  }

  return (
    <>
      <PaymentRecordDetailLabel>明細</PaymentRecordDetailLabel>
      <PaymentRecordDetailSection>
        <Row>
          <RowHeaderLabel>区分</RowHeaderLabel>
          <RowHeaderLabel>細目</RowHeaderLabel>
          <RowHeaderLabel>支払金額</RowHeaderLabel>
          <RowHeaderLabel>源泉徴収額</RowHeaderLabel>
        </Row>
        {rowIds.map((id) => (
          <PaymentRecordDetailRow key={id} detail={details[id] ?? initialValue} rowId={id} />
        ))}
      </PaymentRecordDetailSection>
    </>
  );
}
function PaymentRecordFormInput({ entity }: FormProps): ReactElement {
  return (
    <>
      <FormItemWithLabel label="支払いを受ける者氏名" marginBottom="1em">
        <Input disabled defaultValue={entity.earner_name} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払いを受ける者住所" marginBottom="1em">
        <Input disabled defaultValue={entity.earner_address} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払いを受ける者事業者番号" marginBottom="2em">
        <Input disabled defaultValue={entity.earner_individual_or_corporate_number} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者氏名">
        <Input disabled defaultValue={entity.payer_name} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者住所">
        <Input disabled defaultValue={entity.payer_address} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者事業者番号" marginBottom="1em">
        <Input disabled defaultValue={entity.payer_individual_or_corporate_number} />
      </FormItemWithLabel>
      <PaymentForm entity={entity} />
    </>
  );
}

type PaymentRecordDetailRowProps = {
  detail: Payment;
  rowId: string;
};
function PaymentRecordDetailRow({ detail, rowId }: PaymentRecordDetailRowProps): ReactElement {
  return (
    <Row>
      <Form.Item initialValue={detail.classification ?? 0} name={['payment', rowId, 'classification']}>
        <InputNumberStyle disabled />
      </Form.Item>
      <Form.Item initialValue={detail.detail ?? 0} name={['payment', rowId, 'detail']}>
        <InputNumberStyle disabled />
      </Form.Item>
      <Form.Item
        initialValue={detail.payment_amount ?? 0}
        name={['payment', rowId, 'payment_amount']}
        style={{ textAlign: 'right' }}>
        <InputNumberStyle disabled />
      </Form.Item>
      <Form.Item
        initialValue={detail.withholding_tax_amount ?? 0}
        name={['payment', rowId, 'withholding_tax_amount']}
        style={{ textAlign: 'right' }}>
        <Input disabled />
      </Form.Item>
    </Row>
  );
}

function calcTotalAmount(payments: Payment[] | undefined): number {
  if (payments === undefined) {
    return 0;
  }
  const total_amount: number = payments.reduce((accumulator, payment) => {
    if (typeof payment.payment_amount === 'number') {
      return accumulator + payment.payment_amount;
    }
    if (typeof payment.payment_amount === 'string') {
      const payment_amount = parseInt((payment.payment_amount as string).replace(/,/g, ''));
      return accumulator + payment_amount;
    }
    return accumulator;
  }, 0);

  return total_amount;
}

function renderTableTotalAmountColumn(payments: Payment[]): ReactElement {
  const totalAmount = calcTotalAmount(payments).toLocaleString();
  return <>{totalAmount}</>;
}

function expandPaymentTable(record: ResultPageDocument<DocumentPaymentRecordEntity>): ReactNode {
  if (record.error) {
    return;
  }
  const payments = record.latest_revision.payments;

  const data: Record<string, string | number>[] = payments
    ? payments.map((payment) => {
        return {
          classification: payment.classification ?? '',
          detail: payment.detail ?? '',
          payment_amount: payment.payment_amount ?? 0,
          withholding_tax_amount: payment.withholding_tax_amount ?? 0,
        };
      })
    : [
        {
          classification: '',
          detail: '',
          payment_amount: 0,
          withholding_tax_amount: 0,
        },
      ];

  return (
    <Table dataSource={data} pagination={false}>
      <Column key="classification" dataIndex="classification" title="区分" />
      <Column key="detail" dataIndex="detail" title="細目" />
      <Column key="payment_amount" dataIndex="payment_amount" title="支払い金額" />
      <Column key="withholding_tax_amount" dataIndex="withholding_tax_amount" title="源泉徴収額" />
    </Table>
  );
}

const paymentRecordPageDefinition: OcrResultPageDefinition<DocumentPaymentRecordEntity> = {
  name: '(ベータ版)支払い調書',
  tableColumns: [
    {
      dataIndex: ['latest_revision', 'earner_name'],
      title: '支払いを受ける者氏名',
      render: renderTableColumn,
    },
    {
      dataIndex: ['latest_revision', 'payments'],
      title: '支払い合計金額',
      render: renderTableTotalAmountColumn,
    },
    {
      dataIndex: ['latest_revision', 'payer_name'],
      title: '支払い者',
      render: renderTableColumn,
    },
  ],
  tableExpandable: {
    expandedRowRender: expandPaymentTable,
  },

  formComponent: PaymentRecordFormInput,
};

export default paymentRecordPageDefinition;
