import { message as notifier } from 'antd';

export const notifySuccess = (message: string): void => {
  notifier.success(message);
};

export const notifyWarning = (message: string): void => {
  notifier.warning(message);
};

export const notifyError = (message: string): void => {
  notifier.error(message);
};
