import { useCallback } from 'react';

import { companiesApi, usageApi } from '../../api/apiClient';
import CsvDownloadButton from '../../components/Button/CsvDownloadButton';
import { Paths } from '../../types/Paths';
import toMap from '../../utils/toMap';

export type UsageAtMonth = {
  month: string;
  company_id: string;
  company_rpa_id: string;
  company_name: string;
  plan_name: string;
  base_fee: number;
  usage: number;
  free_usage: number;
  bill: number;
  bill_with_tax: number;
  over_billing: number;
};
const csvColumns: Record<string, Paths<UsageAtMonth>> = {
  年月: ['month'],
  顧客番号: ['company_rpa_id'],
  会社名: ['company_name'],
  料金プラン: ['plan_name'],
  基本料金: ['base_fee'],
  使用枚数: ['usage'],
  対象外使用枚数: ['free_usage'],
  '使用料金(税抜)': ['bill'],
  '使用料金(税込)': ['bill_with_tax'],
  過剰請求分返金額: ['over_billing'],
};

interface Props {
  disabled: boolean;
  month: string;
}
const DownloadUsageAtMonth = ({ disabled, month }: Props) => {
  const getRows = useCallback(async (): Promise<UsageAtMonth[]> => {
    const [{ data: usages }, { data: companies }] = await Promise.all([
      usageApi.usageControllerGetUsagesAtMonth(month),
      companiesApi.companyControllerGetAllCompanyList(),
    ]);
    const companyMap = toMap(companies, 'company_id');
    return usages.map((item) => {
      const company = companyMap.get(item.company_id);
      return {
        month: item.month,
        company_id: item.company_id,
        company_rpa_id: company?.company_rpa_id ?? '',
        company_name: company?.company_name ?? '-',
        plan_name: item?.plan_name ?? '-',
        base_fee: item?.base_fee ?? 0,
        usage: item.usage,
        free_usage: item.free_usage,
        bill: item.bill,
        bill_with_tax: item.bill_with_tax,
        over_billing: item.over_billing ?? 0,
      };
    });
  }, [month]);

  return (
    <CsvDownloadButton columns={csvColumns} disabled={disabled} fileName={`usage-${month}.csv`} getRows={getRows} />
  );
};

export default DownloadUsageAtMonth;
