import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAuthStore } from '../../store/useAuthStore';
import { useMeStore } from '../../store/useMeStore';

const AuthenticatedRoute = (props: RouteProps) => {
  const [, isAuthorized] = useAuthStore();
  const { passwordChangeRequired } = useMeStore();

  if (isAuthorized) {
    return passwordChangeRequired ? <Redirect to="/password/change" /> : <Route {...props} />;
  } else {
    return <Redirect to="/login" />;
  }
};

export default AuthenticatedRoute;
