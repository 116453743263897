import { Card, Table, Tabs } from 'antd';
import Column from 'antd/lib/table/Column';
import { useMemo, useState } from 'react';

import { DocumentGeneralTableEntity } from '../../../generated-api';
import { OcrResultPageDefinition } from './types';

const { TabPane } = Tabs;

type ExpandChildTableProps = {
  table: string[][];
};

interface GeneralTableFormProps {
  entity: DocumentGeneralTableEntity;
}
function GeneralTableFormComponent({ entity }: GeneralTableFormProps) {
  const [tab, setTab] = useState('0');
  const formEntities = useMemo(
    () =>
      entity.tables.map(([cols, ...table]) => ({
        column_names: cols,
        rows: table,
      })),
    [entity.tables]
  );

  return (
    <Tabs defaultActiveKey={tab} onChange={setTab}>
      {formEntities.map((table, index) => (
        <TabPane key={index} tab={`表${index + 1}`} tabKey={index.toString()}>
          <ExpandChildTable table={table.rows} />
        </TabPane>
      ))}
    </Tabs>
  );
}

function ExpandChildTable({ table }: ExpandChildTableProps) {
  const { header, values } = useMemo(() => {
    const [header, ...values] = table;
    return {
      header,
      // antd が rowKey を振れるように変換する
      values: values.map((value, index) => ({
        index,
        row: value.reduce<Record<string, string>>(
          (acc, v, index) => ({
            ...acc,
            [index]: v,
          }),
          {}
        ),
      })),
    };
  }, [table]);

  return (
    <Card>
      <Table dataSource={values} pagination={false} rowKey="index">
        {header.map((col, index) => (
          <Column<typeof values[0]>
            key={`col-${index}`}
            dataIndex={['row', index]}
            render={(value) => `${value}`}
            title={col}
          />
        ))}
      </Table>
    </Card>
  );
}

const generalTableResultPageDefinition: OcrResultPageDefinition<DocumentGeneralTableEntity> = {
  name: '(ベータ版)表データ',
  tableColumns: [
    {
      title: '表の数',
      dataIndex: ['latest_revision', 'tables'],
      render: (value: string[][][]) => value?.length,
    },
  ],
  tableExpandable: {
    expandedRowRender: ({ latest_revision: record }) =>
      record.tables.map((table, tableIndex) => <ExpandChildTable key={tableIndex} table={table} />),
  },
  formComponent: GeneralTableFormComponent,
};

export default generalTableResultPageDefinition;
