import { ReactElement } from 'react';

import { DocumentOcrErrorEntity } from '../../../generated-api';
import { OcrResultPageDefinition } from './types';

function OcrErrorFormInputs(): ReactElement {
  return (
    <>
      <p>読み取り中にエラーが発生しました。</p>
    </>
  );
}

const ocrErrorResultPageDefinition: OcrResultPageDefinition<DocumentOcrErrorEntity> = {
  name: '読み取りエラー',
  formComponent: OcrErrorFormInputs,
  tableColumns: [],
};
export default ocrErrorResultPageDefinition;
