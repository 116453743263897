import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SuccessIcon } from '../../components/Icons/StatusIcon';
import UserName from '../../components/User/UserName';
import { DocumentType, SearchResult } from '../../generated-api';
import ocrTypeDefinitions from './defs';
interface Props {
  results: SearchResult[];
  loading: boolean;
}

const columns: ColumnsType<SearchResult> = [
  {
    title: '読み取り日時',
    dataIndex: ['uploaded_at'],
    render: (readDate) => format(parseISO(readDate), 'yyyy-MM-dd'),
    width: '8rem',
  },
  { title: '実施者', dataIndex: ['user_id'], render: (userId) => <UserName userId={userId} /> },
  {
    title: '帳票の種類',
    dataIndex: ['ocr_type'],
    render: (documentType: DocumentType) => ocrTypeDefinitions[documentType] ?? '(非対応の書類)',
    width: '8rem',
  },
  {
    title: '全データ確認済み',
    render: (_, record) => <SeenAll seenDocuments={record.seen_documents} totalPage={record.total_page} />,
    width: '6rem',
  },
  {
    title: 'ファイル名',
    dataIndex: ['file_names'],
    render: (fileNames) => fileNames && fileNames.join(', '),
  },
  { title: '総ページ数', dataIndex: ['total_page'], width: '8rem' },
  {
    title: '',
    render: (_, record) => (
      <DetailButton
        disabled={ocrTypeDefinitions[record.ocr_type] == null}
        documentType={record.ocr_type}
        groupId={record.group_id}
      />
    ),
    width: '8rem',
  },
];

const pagination: TablePaginationConfig = {
  defaultPageSize: 20,
  showSizeChanger: true,
  position: ['topRight', 'bottomRight'],
};

const SearchTable = ({ loading, results }: Props) => {
  return (
    <Table columns={columns} dataSource={results} loading={loading} pagination={pagination} rowKey="group_id"></Table>
  );
};

interface SeenAllProps {
  seenDocuments: string[];
  totalPage: number;
}

const SeenAll = ({ seenDocuments, totalPage }: SeenAllProps) => {
  if (seenDocuments.length != 0 && seenDocuments.length == totalPage) {
    return <SuccessIcon />;
  }
  return <></>;
};

interface DetailButtonProps {
  documentType: string | undefined;
  groupId: string;
  disabled?: boolean;
}

const DetailButton = ({ disabled = false, documentType, groupId }: DetailButtonProps) => {
  const className = classNames(['ant-btn', 'ant-btn-primary'], {
    disabled,
  });

  return (
    <DetailLink className={className} to={`/result/${documentType}/${groupId}`}>
      詳細
    </DetailLink>
  );
};

export default SearchTable;

const DetailLink = styled(Link)`
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    pointer-events: none;
  }
`;
