import { Button, Layout, Popover, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useCommitLogout } from '../../store/useAuthStore';
import { useMeStore } from '../../store/useMeStore';

const { Text } = Typography;

const Header = () => {
  const me = useMeStore();
  const logout = useCommitLogout();
  return (
    <Container>
      <ProductName>
        <ProductLink to="/">
          <Image src="/logo_header.png" />
        </ProductLink>
      </ProductName>
      <SiteType>管理者サイト</SiteType>
      <Popover
        content={
          <PopoverContent>
            <MyPageLink to="/mypage/">マイページ</MyPageLink>
            <Button type="link" onClick={() => logout()}>
              ログアウト
            </Button>
          </PopoverContent>
        }
        placement="left">
        {me.userName && <UserName>{me.userName} さん</UserName>}
      </Popover>
    </Container>
  );
};

export default Header;

const Container = styled(Layout.Header)`
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #8da9db;
  padding: 0 25px;
`;

const ProductName = styled.h1`
  margin: 0;
`;

const SiteType = styled(Text)`
  margin-left: 3rem;
`;

const ProductLink = styled(Link)`
  color: #000;

  :hover {
    color: #000;
  }
`;

const Image = styled.img`
  height: 70px;
  object-fit: cover;
`;

const UserName = styled(Text)`
  display: inline-block;
  margin-left: auto;
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const MyPageLink = styled(Link)`
  display: inline-block;
  padding: 4px 15px;
`;
