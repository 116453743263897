import { Paths } from '../types/Paths';

function getPropertyOf<T>(data: T, keys: Paths<T>): unknown {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (keys as string[]).reduce<any>((current, key) => current?.[key], data);
}

export default function convertCSVColumns<T>(data: T[], columns: Record<string, Paths<T>>): Record<string, string>[] {
  const keys = Object.values(columns);
  const headers = Object.keys(columns);

  const records = data.map((record) =>
    keys.map((key) => {
      const property = getPropertyOf(record, key);
      return property == null ? '' : String(property);
    })
  );

  return records.map((record) => {
    return record.reduce<Record<string, string>>((accumulator, current, index) => {
      accumulator[headers[index]] = current;
      return accumulator;
    }, {});
  });
}
