import DataLoader from 'dataloader';

import { CompanyPlan } from '../generated-api';
import { companiesApi } from './apiClient';

export class CompanyPlansDataLoader {
  private loader = new DataLoader<string, CompanyPlan | null>(
    async (keys) => {
      if (keys.length === 0) {
        return [];
      }
      const resultMap: Map<string, CompanyPlan> = new Map();
      const { data } = await companiesApi.companyControllerGetCompanyLastPlans(keys.join(','));
      data.forEach((value) => resultMap.set(value.company_id, value));

      // keys の順番と結果の順番が一致している必要があるため一度 map に詰めて key を使って取り出す
      return keys.map((key) => resultMap.get(key) ?? null);
    },
    {
      // フロントのページングのサイズが最大100なのでそれぐらい
      maxBatchSize: 100,
    }
  );

  public async getCompanyPlanById(companyId: string): Promise<CompanyPlan | null> {
    return this.loader.load(companyId);
  }

  public async getCompanyPlansByIds(companyIds: string[]): Promise<Array<CompanyPlan | null>> {
    const result = await this.loader.loadMany(companyIds);
    return result.filter((v): v is CompanyPlan => !(v instanceof Error));
  }
}

const companyPlansDataLoader = new CompanyPlansDataLoader();

export default companyPlansDataLoader;
