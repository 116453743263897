import { Button, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { companiesApi } from '../../api/apiClient';
import LoadingIcon from '../../components/Loading/LoadingIcon';
import { Plan } from '../../generated-api';
import useAPIData from '../../hooks/useAPIData';
import { CompanyColumnPlanName, CompanyColumnUserName } from './CompanyColumn';
import DownloadCompanyPlans from './DownloadCompanyPlans';

type CompanyRecord = {
  company_id: string;
  company_name: string;
  company_rpa_id?: string;
  user_id: string;
};

const tableCols = (
  editCompany: (companyId: string) => void,
  companies: CompanyRecord[],
  plans: Plan[]
): ColumnsType<CompanyRecord> => {
  const companyNameFilters = companies.map((company) => {
    return {
      text: company.company_name,
      value: company.company_name,
    };
  });

  return [
    {
      title: '組織名',
      dataIndex: 'company_name',
      filters: companyNameFilters,
      filterSearch: true,
      onFilter: (value: string | number | boolean, record: CompanyRecord) =>
        record.company_name.startsWith(value as string),
    },
    {
      title: '顧客番号',
      dataIndex: 'company_rpa_id',
    },
    {
      title: '担当者名',
      dataIndex: 'user_id',
      render: (userId: string) => <CompanyColumnUserName userId={userId} />,
    },
    {
      title: 'プラン',
      dataIndex: 'company_id',
      render: (companyId: string) => <CompanyColumnPlanName companyId={companyId} plans={plans} />,
    },
    {
      render: (record: CompanyRecord) => (
        <Button type="primary" onClick={() => editCompany(record.company_id)}>
          編集
        </Button>
      ),
    },
  ];
};

const CompanyList = () => {
  const companies = useAPIData(async () => {
    const { data } = await companiesApi.companyControllerGetActiveCompanyList();
    return data;
  }).data;

  const plans = useAPIData(async () => {
    const { data } = await companiesApi.companyControllerGetPlanList();
    return data;
  }).data;

  const companyRecords = useMemo(
    () =>
      companies?.map((company) => ({
        company_id: company.company_id,
        company_name: company.company_name,
        company_rpa_id: company.company_rpa_id,
        user_id: company.representative_user_id,
      })),

    [companies]
  );

  const history = useHistory();
  const createCompany = useCallback(() => {
    history.push('/create/');
  }, [history]);

  const editCompany = useCallback(
    (companyId) => {
      history.push('/' + companyId);
    },
    [history]
  );

  const transitionInactivatedList = useCallback(() => {
    history.push('/inactive/');
  }, [history]);

  const columns = useMemo(() => {
    if (companyRecords && plans) {
      return tableCols(editCompany, companyRecords, plans);
    } else {
      return undefined;
    }
  }, [companyRecords, editCompany, plans]);

  return (
    <Container>
      <Heading>
        <PageTitle>利用組織管理</PageTitle>
        <Buttons>
          <Button type="primary" onClick={createCompany}>
            新規作成
          </Button>
          <Button type="primary" onClick={transitionInactivatedList}>
            退会した組織
          </Button>
          <DownloadCompanyPlans companies={companies ?? []} plans={plans ?? []} />
        </Buttons>
      </Heading>
      <Content>
        {!companyRecords && (
          <LoadingIconWrapper>
            <LoadingIcon />
          </LoadingIconWrapper>
        )}
        {companyRecords && (
          <Table
            columns={columns}
            dataSource={companyRecords}
            pagination={{ defaultPageSize: 20, showSizeChanger: true, position: ['topRight', 'bottomRight'] }}
            rowKey="company_id"
          />
        )}
      </Content>
    </Container>
  );
};

export default CompanyList;

const Container = styled.div`
  width: 80%;
  padding: 24px;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #5a89d0;
  padding-right: 40px;
`;

const PageTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: #5a89d0;
`;

const Buttons = styled(Space)`
  align-items: unset;
`;

const Content = styled.div`
  width: 90%;
  margin-left: 24px;
  margin-top: 36px;
`;

const LoadingIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
