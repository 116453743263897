import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format, isThisMonth, parseISO } from 'date-fns';
import { useMemo } from 'react';

import { DocumentType, UsagePerForm } from '../../generated-api';

export interface StatsFormTableData extends Partial<Record<DocumentType, number>> {
  month: string;
  total: number;
  confirmed: boolean; // 未確定ならfalse
}

type Props = {
  usages: UsagePerForm[] | undefined;
};

const StatsFormTable = ({ usages }: Props) => {
  const dataSource = useMemo((): StatsFormTableData[] => {
    const formMonthToData = new Map<string, Partial<Record<DocumentType, number>>>();
    for (const usage of usages ?? []) {
      const monthStat = formMonthToData.get(usage.month) ?? {};
      monthStat[usage.ocr_type] = usage.usage;
      formMonthToData.set(usage.month, monthStat);
    }
    return [...formMonthToData.entries()].map(([month, usages]) => ({
      ...usages,
      month,
      confirmed: !isThisMonth(parseISO(month)),
      total: Object.values(usages).reduce<number>((previous, current) => previous + (current ?? 0), 0),
    }));
  }, [usages]);
  const columns = useMemo(
    (): ColumnsType<StatsFormTableData> => [
      {
        title: 'ご利用月',
        dataIndex: 'month',
        render: (value, record) => {
          const formattedDate = format(parseISO(value), 'yyyy/M');
          if (record.confirmed) {
            return formattedDate;
          } else {
            return '（未確定）' + formattedDate;
          }
        },
        width: 300,
        align: 'right',
      },
      {
        title: '利用枚数',
        children: [
          {
            title: 'ふるさと納税',
            dataIndex: 'furusato_tax',
            render: renderUsageColumn,
            width: 240,
            align: 'right',
          },
          {
            title: '医療費',
            dataIndex: 'medical_bill',
            render: renderUsageColumn,
            width: 240,
            align: 'right',
          },
          {
            title: '通帳コピー',
            dataIndex: 'bankbook',
            render: renderUsageColumn,
            width: 240,
            align: 'right',
          },
          {
            title: 'カード明細',
            dataIndex: 'card_statement',
            render: renderUsageColumn,
            width: 240,
            align: 'right',
          },
          {
            title: '(ベータ版)源泉徴収票',
            dataIndex: 'withholding_slip',
            render: renderUsageColumn,
            width: 240,
            align: 'right',
          },
          {
            title: '領収書',
            dataIndex: 'receipt',
            render: (_: number, record: StatsFormTableData) => {
              const usage = Object.entries(record)
                .filter(([key, value]) => key.includes('receipt') && typeof value === 'number')
                .reduce<number>((sum, [_, value]) => sum + (value as number), 0);
              return <>{(usage ?? 0).toLocaleString() + '枚'}</>;
            },
            width: 240,
            align: 'right',
          },
          {
            title: '(ベータ版)支払い調書',
            dataIndex: 'payment_record',
            render: renderUsageColumn,
            width: 240,
            align: 'right',
          },
          {
            title: '(ベータ版)表データ',
            dataIndex: 'general_table',
            render: renderUsageColumn,
            width: 240,
            align: 'right',
          },
          {
            title: '(ベータ版)請求書',
            dataIndex: 'invoice',
            render: renderUsageColumn,
            width: 240,
            align: 'right',
          },
          {
            title: '合計枚数',
            dataIndex: 'total',
            render: renderUsageColumn,
            width: 240,
            align: 'right',
          },
        ],
      },
    ],
    []
  );

  return <Table bordered={true} columns={columns} dataSource={dataSource} pagination={false} rowKey="month" />;
};

function renderUsageColumn(value: number) {
  return <>{(value ?? 0).toLocaleString() + '枚'}</>;
}

export default StatsFormTable;
