import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format, isThisMonth, parseISO } from 'date-fns';
import { ReactElement, useMemo } from 'react';

import { CompanyUsage } from '../../generated-api';

export type CompanyUsageTableRow = Pick<CompanyUsage, 'month' | 'bill' | 'bill_with_tax' | 'company_id'> & {
  tax: number;
  confirmed: boolean;
};

type Props = {
  companyUsages: CompanyUsage[] | undefined;
};

const CompanyUsageTable = ({ companyUsages }: Props) => {
  const dataSource = useMemo(
    () =>
      companyUsages?.map((companyUsage) => {
        return {
          ...companyUsage,
          tax: companyUsage.bill_with_tax - companyUsage.bill,
          confirmed: !isThisMonth(parseISO(companyUsage.month)),
        };
      }),
    [companyUsages]
  );
  const columns = useMemo((): ColumnsType<CompanyUsageTableRow> => {
    return [
      {
        title: 'ご利用月',
        dataIndex: 'month',
        render: (value, record) => {
          const formattedDate = format(parseISO(value), 'yyyy/M');
          if (record.confirmed) {
            return formattedDate;
          } else {
            return '（未確定）' + formattedDate;
          }
        },
        width: 300,
        align: 'right',
      },
      {
        title: '利用料金（税抜き）',
        dataIndex: 'bill',
        render: renderMoneyAmountColumn,
        width: 300,
        align: 'right',
      },
      {
        title: '消費税',
        dataIndex: 'tax',
        render: renderMoneyAmountColumn,
        width: 300,
        align: 'right',
      },
      {
        title: '利用料金（税込み）',
        dataIndex: 'bill_with_tax',
        render: renderMoneyAmountColumn,
        width: 300,
        align: 'right',
      },
    ];
  }, []);

  return <Table bordered={true} columns={columns} dataSource={dataSource} pagination={false} rowKey="month" />;
};

function renderMoneyAmountColumn(value: number, record: CompanyUsageTableRow): ReactElement {
  const formattedValue = value.toLocaleString();
  return <>{record.confirmed ? formattedValue + '円' : '（未確定）' + formattedValue + '円'}</>;
}

export default CompanyUsageTable;
