import { useCallback, useState } from 'react';
import { useAsyncRetry } from 'react-use';
import styled from 'styled-components';

import { documentApi } from '../../api/apiClient';
import { DocumentType } from '../../generated-api';
import SearchForm from './SearchForm';
import SearchTable from './SearchTable';

const SearchPage = () => {
  const [documentType, setDocumentType] = useState<DocumentType | undefined>(undefined);
  const [companyId, setCompanyId] = useState<string | undefined>(undefined);
  const [since, setSince] = useState<string | undefined>(undefined);
  const [until, setUntil] = useState<string | undefined>(undefined);

  const { loading, retry, value } = useAsyncRetry(async () => {
    if (!companyId) {
      return;
    }
    const { data: searchResults } = await documentApi.documentControllerSearch(companyId, documentType, since, until);
    return searchResults;
  });

  const updateList = useCallback(
    async (companyId: string, documentType?: DocumentType, since?: string, until?: string) => {
      setCompanyId(companyId);
      setDocumentType(documentType);
      setSince(since);
      setUntil(until);
      retry();
    },
    [retry]
  );

  return (
    <Container>
      <PageHeading>過去読み取り結果</PageHeading>
      <SearchForm updateList={updateList} />
      <SearchTable loading={loading} results={value?.documents ?? []} />
    </Container>
  );
};

export default SearchPage;

const Container = styled.div`
  width: 80%;
  padding: 24px;
`;

const PageHeading = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: #5a89d0;
  border-bottom: solid 2px #5a89d0;
`;
