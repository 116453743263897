import { Input, InputNumber } from 'antd';
import { ReactElement } from 'react';

import FormItemWithLabel from '../../../components/Form/FormItemWithLabel';
import { DocumentFurusatoTaxEntity } from '../../../generated-api';
import renderTableColumn from './renderTableColumn';
import { OcrResultPageDefinition } from './types';

interface Props {
  entity: DocumentFurusatoTaxEntity;
}
function FurusatoTaxFormInputs({ entity }: Props): ReactElement {
  return (
    <>
      <FormItemWithLabel label="収納日">
        <Input disabled defaultValue={entity.payment_day} />
      </FormItemWithLabel>
      <FormItemWithLabel label="金額">
        <InputNumber disabled defaultValue={entity.amount} />
      </FormItemWithLabel>
      <FormItemWithLabel label="都道府県">
        <Input disabled defaultValue={entity.prefecture} />
      </FormItemWithLabel>
      <FormItemWithLabel label="都市">
        <Input disabled defaultValue={entity.city} />
      </FormItemWithLabel>
      <FormItemWithLabel label="自治体所在地">
        <Input disabled defaultValue={entity.address} />
      </FormItemWithLabel>
    </>
  );
}

const furusatoTaxResultPageDefinition: OcrResultPageDefinition<DocumentFurusatoTaxEntity> = {
  name: 'ふるさと納税証明書',
  formComponent: FurusatoTaxFormInputs,
  tableColumns: [
    { dataIndex: ['latest_revision', 'payment_day'], title: '収納日', render: renderTableColumn },
    { dataIndex: ['latest_revision', 'amount'], title: '金額', render: renderTableColumn },
    { dataIndex: ['latest_revision', 'prefecture'], title: '都道府県', render: renderTableColumn },
    { dataIndex: ['latest_revision', 'city'], title: '都市', render: renderTableColumn },
    { dataIndex: ['latest_revision', 'address'], title: '自治体所在地', render: renderTableColumn },
  ],
};
export default furusatoTaxResultPageDefinition;
