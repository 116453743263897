export default function createCSVData(input: Record<string, string>[]): string {
  const headers = [
    ...new Set(
      input.reduce<string[]>((accumulator, record) => {
        accumulator = [...accumulator, ...Object.keys(record)];
        return accumulator;
      }, [])
    ),
  ];

  const records = input.map((record) => {
    return headers.map((key) => {
      return record[key] ?? '';
    });
  });

  const data = [headers, ...records];

  return data.map((row) => '"' + row.map((v) => v.replaceAll('"', '""')).join('","') + '"').join('\n');
}
