import './App.css';

import { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import PageContainer from './components/Container/PageContainer';
import AuthenticatedRoute from './components/Lib/AuthenticatedRoute';
import LoadingScreen from './components/Loading/LoadingScreen';
import AdminUserCreate from './page/AdminUser/AdminUserCreate';
import AdminUserList from './page/AdminUser/AdminUserList';
import AdminUserUpdate from './page/AdminUser/AdminUserUpdate';
import CompanyCreate from './page/Company/CompanyCreate';
import CompanyList from './page/Company/CompanyList';
import CompanyUpdate from './page/Company/CompanyUpdate';
import InactivatedCompanyEdit from './page/Company/InactivatedCompanyEdit';
import InactivatedCompanyList from './page/Company/InactivatedCompanyList';
import LoginPage from './page/Login/LoginPage';
import SendMailPage from './page/Mail/SendMailPage';
import MyPage from './page/MyPage/MyPage';
import OcrResultPage from './page/OcrResult/OcrResultPage';
import UsageOutputPage from './page/Output/UsageOutputPage';
import PasswordChangePage from './page/PasswordChange/PasswordChangePage';
import PasswordForgetPage from './page/PasswordForget/PasswordForgetPage';
import SearchPage from './page/Search/SearchPage';
import UsagePage from './page/Usage/UsagePage';
import { useAuthStore, useInitAuth } from './store/useAuthStore';

const App = () => {
  const [isAuthInitiated] = useAuthStore();

  const initAuth = useInitAuth();

  useEffect(() => {
    initAuth();
  }, [initAuth]);

  return !isAuthInitiated ? (
    <LoadingScreen />
  ) : (
    <BrowserRouter>
      <Switch>
        <Route component={LoginPage} path="/login" />
        <Route component={PasswordForgetPage} path="/password/forget" />
        <Route component={PasswordChangePage} path="/password/change" />
        <PageContainer>
          <AuthenticatedRoute exact component={CompanyList} path="/" />
          <AuthenticatedRoute exact component={InactivatedCompanyList} path="/inactive/" />
          <AuthenticatedRoute exact component={InactivatedCompanyEdit} path="/inactive/:id" />
          <AuthenticatedRoute exact strict component={MyPage} path="/mypage/" />
          <AuthenticatedRoute exact strict component={CompanyUpdate} path="/:id" />
          <AuthenticatedRoute exact strict component={CompanyCreate} path="/create/" />
          <AuthenticatedRoute exact strict component={AdminUserList} path="/users/" />
          <AuthenticatedRoute exact strict component={AdminUserUpdate} path="/users/:id" />
          <AuthenticatedRoute exact strict component={AdminUserCreate} path="/users/create/" />
          <AuthenticatedRoute exact strict component={UsagePage} path="/usage/" />
          <AuthenticatedRoute exact strict component={UsageOutputPage} path="/usage/output" />
          <AuthenticatedRoute exact strict component={SendMailPage} path="/mail/" />
          <AuthenticatedRoute exact component={SearchPage} path="/documents/search" />
          <AuthenticatedRoute exact component={OcrResultPage} path="/result/:type/:group" />
        </PageContainer>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
