import { Button, Form, Input, Modal, Upload } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RcFile } from 'antd/lib/upload';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { emailApi } from '../../api/apiClient';
import PageLayout from '../../components/Container/PageLayout';
import { notifyError, notifySuccess } from '../../utils/notification';

interface SendMailForm {
  subject: string;
  text: string;
}

const allowFileTypes = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
const footer = `\n\n------------------\n\
【お知らせ】\n\\\\\\毎週木曜日16-17時 OCR無料相談会開催中///\n\
●Ez-AiOCRに関するご質問にその場でお答えします！\n\
●以下のZOOMよりご自由にご参加ください！\n\
https://us02web.zoom.us/j/6181711280?pwd=TU50MWpTRHNrN0hiWms4MVRvUzBmdz09\n\
------------------\n\
Ezテクノロジーズ株式会社 Ez-AiOCRお客様サポート担当\n\
〒105-0022 東京都港区海岸１丁目４番２２号 SNビル4F\n\
03-6416-1895\n\
ocr-support@rpa-solutions.co.jp`;

const SendMailPage = () => {
  const history = useHistory();
  const [form] = useForm<SendMailForm>();
  const [files, setFiles] = useState<RcFile[]>([]);

  const send = useCallback(
    (values: SendMailForm) => {
      Modal.confirm({
        icon: null,
        title: '以下の内容でメール送信しますか？',
        content: (
          <>
            <ConfirmLabel>件名</ConfirmLabel>
            <ConfirmSubject> {values.subject}</ConfirmSubject>
            <ConfirmLabel>本文</ConfirmLabel>
            <ConfirmText>{values.text}</ConfirmText>
            <ConfirmLabel>添付ファイル</ConfirmLabel>
            <ConfirmFiles>
              {files.map((file) => {
                return <li key={file.uid}>{file.name}</li>;
              })}
            </ConfirmFiles>
          </>
        ),
        okText: '送信',
        cancelText: 'キャンセル',
        width: 600,
        onOk: async () => {
          try {
            emailApi.emailControllerSendAllUsers(files, values.subject, values.text);
            notifySuccess('メールを送信しました。');
            history.push('/');
          } catch {
            notifyError('メール送信に失敗しました。');
          }
        },
      });
    },
    [files, history]
  );

  const read = useCallback((file: RcFile) => {
    if (file.type == null || !allowFileTypes.includes(file.type)) {
      notifyError(`「${file.name}」は添付が許可されていないファイル形式です。`);
      return Upload.LIST_IGNORE;
    }

    if (file.size > 20 * 1024 * 1024) {
      notifyError(`「${file.name}」のファイルサイズが大きすぎます。アップロード出来るのは 20MB までです。`);
      return Upload.LIST_IGNORE;
    }
    setFiles((state) => [...state, file]);
    return false;
  }, []);

  const remove = useCallback(
    (file: unknown) => {
      const copyFiles = [...files];
      const index = copyFiles.indexOf(file as RcFile);
      copyFiles.splice(index, 1);
      setFiles([...copyFiles]);
    },
    [files]
  );

  return (
    <PageLayout heading="メール送信">
      <FormContainer
        form={form}
        layout="vertical"
        onFinish={useCallback((values) => send(values as SendMailForm), [send])}>
        <Form.Item label="件名" name="subject" rules={[{ required: true, message: '件名を入力してください' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="本文" name="text" rules={[{ required: true, message: '本文を入力してください' }]}>
          <TextArea autoSize={{ minRows: 6, maxRows: 15 }} defaultValue={footer} />
        </Form.Item>
        <Form.Item>
          <Upload multiple beforeUpload={read} fileList={files} name="file" onRemove={remove}>
            <Button>添付ファイル</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            全ユーザへ送信
          </Button>
        </Form.Item>
      </FormContainer>
    </PageLayout>
  );
};

export default SendMailPage;

const FormContainer = styled(Form)`
  width: 600px;
`;

const TextArea = styled(Input.TextArea)`
  white-space: pre-wrap;
`;

const ConfirmLabel = styled.div`
  margin-top: 16px;
`;

const ConfirmSubject = styled.div`
  padding: 8px;
`;

const ConfirmText = styled.div`
  padding: 8px;
  white-space: pre-wrap;
`;

const ConfirmFiles = styled.div`
  padding: 8px;
`;
