import bankBookResultPageDefinition from './bankBookResultPageDefinition';
import cardStatementResultPageDefinition from './cardStatementResultPageDefinition';
import {
  dualHorizontalReceiptResultPageDefinition,
  dualVerticalReceiptResultPageDefinition,
} from './dualReceiptResultPageDefinition';
import furusatoTaxResultPageDefinition from './furusatoTaxResultPageDefinition';
import generalTableResultPageDefinition from './generalTableResultPageDefinition';
import medicalBillResultPageDefinition from './medicalBillResultPageDefinition';
import ocrErrorResultPageDefinition from './ocrErrorResultPageDefinition.';
import paymentRecordPageDefinition from './paymentRecordPageDefinition';
import receiptResultPageDefinition from './receiptResultPageDefinition';
import { DocumentEntities, DocumentEntityOfType, OcrResultPageDefinition } from './types';
import withholdingSlipResultPageDefinition from './withholdingSlipResultPageDefinition';

const ocrResultPageDefinitions: {
  [type in DocumentEntities['type']]: OcrResultPageDefinition<DocumentEntityOfType<type>>;
} = {
  furusato_tax: furusatoTaxResultPageDefinition,
  medical_bill: medicalBillResultPageDefinition,
  bankbook: bankBookResultPageDefinition,
  card_statement: cardStatementResultPageDefinition,
  receipt: receiptResultPageDefinition,
  payment_record: paymentRecordPageDefinition,
  dual_horizontal_receipt: dualHorizontalReceiptResultPageDefinition,
  dual_vertical_receipt: dualVerticalReceiptResultPageDefinition,
  withholding_slip: withholdingSlipResultPageDefinition,
  general_table: generalTableResultPageDefinition,
  ocr_error: ocrErrorResultPageDefinition,
};
export default ocrResultPageDefinitions;
