import { Button, Space, Table, TableProps } from 'antd';
import { ExpandableConfig, TablePaginationConfig } from 'antd/lib/table/interface';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { FailedIcon, SuccessIcon } from '../../components/Icons/StatusIcon';
import { DocumentType } from '../../generated-api';
import ocrResultPageDefinitions from './defs';
import { DocumentEntities, ResultPageDocument } from './defs/types';

type Props = {
  type: DocumentType;
  results: ResultPageDocument[];
  onClickModalOpen: (documentId: string) => void;
  updateList: () => void;
};

const prependColumns: Exclude<TableProps<ResultPageDocument>['columns'], undefined> = [
  {
    title: 'ステータス',
    render: (_, record) => (!record.error && record.fully_read ? <SuccessIcon /> : <FailedIcon />),
  },
  { title: '確認済み', dataIndex: ['seen'], render: (edited) => (edited ? <SuccessIcon /> : <></>), width: '4rem' },
  { title: '編集済み', dataIndex: ['edited'], render: (edited) => (edited ? <SuccessIcon /> : <></>), width: '4rem' },
  {
    title: 'ファイル名',
    render: (_, record) => {
      return <FileName title={record.user_file_path}>{record.parsed_path.fullFileName}</FileName>;
    },
  },
  { title: 'ページ数', dataIndex: ['page'], render: renderColumn },
];

interface ButtonProps {
  record: ResultPageDocument<DocumentEntities>;
  onSee: (documentId: string) => void;
}
const SeeButton = ({ onSee, record }: ButtonProps) => {
  const see = useCallback(() => onSee(record.document_id), [onSee, record]);

  return (
    <Space>
      <Button type="primary" onClick={see}>
        確認
      </Button>
    </Space>
  );
};

const ResultTable = ({ onClickModalOpen, results, type }: Props) => {
  const pagination: TablePaginationConfig = useMemo(() => {
    return { defaultPageSize: 20, showSizeChanger: true, position: ['topRight', 'bottomRight'] };
  }, []);
  const definition = ocrResultPageDefinitions[type];

  return (
    <Table
      columns={[
        ...prependColumns,
        ...(definition.tableColumns as TableProps<ResultPageDocument>['columns'] & object),
        {
          render: (_, record) => <SeeButton record={record} onSee={onClickModalOpen} />,
        },
      ]}
      dataSource={results}
      expandable={{
        rowExpandable: (record) => record.error == null,
        ...(definition.tableExpandable as ExpandableConfig<ResultPageDocument<DocumentEntities>>),
      }}
      pagination={pagination}
      rowKey="document_id"></Table>
  );
};

export default ResultTable;

function renderColumn<T>(v: T) {
  return <>{v == null ? '-' : v}</>;
}

const FileName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 500px;
`;
