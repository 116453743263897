import { ReactNode } from 'react';
import styled from 'styled-components';

import Header from '../Header/Header';

interface Props {
  children: ReactNode;
}

const AuthPageContainer = ({ children }: Props) => {
  return (
    <>
      <Header />
      <Container>
        <ManageSite>管理サイト</ManageSite>
        <Image src="/logo_login_page.png" />
        <Children>{children}</Children>
      </Container>
    </>
  );
};

export default AuthPageContainer;

const Container = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ManageSite = styled.h1`
  color: #888888;
  margin-bottom: 0;
`;

const Image = styled.img`
  width: 250px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 48px;
`;

const Children = styled.div``;
