import { Layout } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';

import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
type Props = {
  children: ReactNode;
};

const { Content } = Layout;

const PageContainer = ({ children }: Props) => {
  return (
    <Container>
      <Header />
      <Main>
        <Sidebar />
        <MainContent>{children}</MainContent>
      </Main>
    </Container>
  );
};

const Container = styled(Layout)`
  min-height: 700px;
  height: 100%;
`;

const Main = styled(Layout)`
  background-color: #fff;
  display: flex;
  flex-direction: row;
`;

const MainContent = styled(Content)`
  overflow: scroll;
`;

export default PageContainer;
