import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { ReactElement, ReactNode } from 'react';

import Badge from '../../../components/Badge/Badge';
import { DocumentBankBookEntity } from '../../../generated-api/api';
import ensureDefined from '../../../utils/ensureDefined';
import { OcrResultPageDefinition, ResultPageDocument } from './types';

interface Props {
  entity: DocumentBankBookEntity;
}
function BankBookFormInput({ entity }: Props): ReactElement {
  return <ExpandedBankBookTable record={entity} />;
}

function wrapExpandedBankBookTable(record: ResultPageDocument<DocumentBankBookEntity>): ReactNode {
  return !record.error ? <ExpandedBankBookTable record={record.latest_revision} /> : <></>;
}

const columnNameMapper = new Map<string, string>([
  ['date', '日付'],
  ['tran_amount1', '取引金額1'],
  ['tran_amount2', '取引金額2'],
  ['balance', '残高'],
]);

interface ExpandedTableProps {
  record: DocumentBankBookEntity;
}
function ExpandedBankBookTable({ record }: ExpandedTableProps): ReactElement {
  const columnNames = ensureDefined(record.column_names);
  const rows = ensureDefined(record.rows);

  const data = rows.map((row, index) => {
    return row.reduce<Record<string, string>>(
      (accumulator, current, index) => {
        accumulator[columnNames[index]] = current;
        return accumulator;
      },
      { 残高計算結果: record.balance_calculation_result?.[index] ?? '' }
    );
  });

  return (
    <Table dataSource={data} pagination={false} scroll={{ y: '780px' }}>
      {record.balance_calculation_result && (
        <Column dataIndex="残高計算結果" render={renderCalcResult} title="残高計算結果" />
      )}
      {columnNames.map((name) => {
        if (columnNameMapper.has(name)) {
          return <Column key={name} dataIndex={name} title={columnNameMapper.get(name)} />;
        } else {
          return <Column key={name} dataIndex={name} title={name} />;
        }
      })}
    </Table>
  );
}

function renderCalcResult(result: string) {
  if (result == '') {
    return <></>;
  }
  if (result == 'true') {
    return <Badge variant="success">問題なし</Badge>;
  } else {
    return <Badge variant="danger">エラー</Badge>;
  }
}

const bankBookResultPageDefinition: OcrResultPageDefinition<DocumentBankBookEntity> = {
  name: '通帳',
  tableColumns: [],
  tableExpandable: {
    expandedRowRender: wrapExpandedBankBookTable,
  },
  formComponent: BankBookFormInput,
};
export default bankBookResultPageDefinition;
