import { Button, Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { companiesApi } from '../../api/apiClient';
import useAPIData from '../../hooks/useAPIData';
import { notifyError } from '../../utils/notification';
import CompanyCreateConfirm from './CompanyCreateConfirm';

export type CompanyCreateForm = {
  company_name: string;
  company_rpa_id: string;
  plan_id: string;
  address: string;
  representative_user_name: string;
  representative_user_email: string;
};

const CompanyCreate = () => {
  const history = useHistory();
  const [form] = useForm<CompanyCreateForm>();

  const plans = useAPIData(async () => {
    const { data } = await companiesApi.companyControllerGetPlanList();
    return data;
  }).data;

  const createCompany = useCallback(
    (formData: CompanyCreateForm) => {
      const planName = plans?.find((plan) => plan.plan_id === formData.plan_id)?.plan_name ?? '';
      Modal.confirm({
        icon: null,
        content: <CompanyCreateConfirm formContent={formData} planName={planName} />,
        okText: '登録する',
        cancelText: 'キャンセル',
        width: 800,
        onOk: async () => {
          try {
            await companiesApi.companyControllerCreateCompany(formData);
            history.push('/');
          } catch (error) {
            console.error(error);
            if (axios.isAxiosError(error)) {
              if (error.response?.status === 403) {
                notifyError(
                  '会社の登録に失敗しました。指定した 担当者メールアドレス は既に他の組織に登録されています。'
                );
                return;
              }
            }
            notifyError('不明なエラーにより会社の登録に失敗しました。お手数ですが、あとでもう一度やり直してください。');
          }
        },
      });
    },
    [history, plans]
  );

  return (
    <Container>
      <Heading>
        <PageTitle>組織新規作成</PageTitle>
        <Button type="primary" onClick={form.submit}>
          登録する
        </Button>
      </Heading>
      <Content>
        <Form
          form={form}
          labelAlign="left"
          labelCol={{ span: 8 }}
          labelWrap={true}
          layout="horizontal"
          onFinish={createCompany}>
          <Form.Item
            label="組織名"
            name="company_name"
            rules={[{ required: true, message: '会社名を入力してください。' }]}>
            <TextInput type="text" />
          </Form.Item>
          <Form.Item initialValue="" label="顧客番号" name="company_rpa_id">
            <TextInput type="text" />
          </Form.Item>
          <Form.Item
            label="利用プラン"
            name="plan_id"
            rules={[{ required: true, message: 'プランを選択してください。' }]}>
            <FixedSizeSelect>
              {plans?.map((plan) => {
                return (
                  <Select.Option key={plan.plan_id} value={plan.plan_id}>
                    {plan.plan_name}
                  </Select.Option>
                );
              })}
            </FixedSizeSelect>
          </Form.Item>
          <Form.Item initialValue="" label="住所" name="address">
            <TextInput type="text" />
          </Form.Item>
          <Form.Item
            label="担当者名"
            name="representative_user_name"
            rules={[{ required: true, message: '担当者名を入力してください。' }]}>
            <TextInput type="text" />
          </Form.Item>
          <Form.Item
            label="担当者メールアドレス"
            name="representative_user_email"
            rules={[{ required: true, type: 'email', message: 'メールアドレスを入力してください。' }]}>
            <TextInput type="text" />
          </Form.Item>
        </Form>
      </Content>
    </Container>
  );
};

export default CompanyCreate;

const Container = styled.div`
  width: 80%;
  padding: 24px;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #5a89d0;
  padding-right: 40px;
`;

const PageTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: #5a89d0;
`;

const Content = styled.div`
  border: 1px solid #707070;
  border-radius: 15px;
  width: 90%;
  margin-left: 24px;
  margin-top: 36px;
  padding: 40px;
`;

const TextInput = styled(Input)`
  width: 320px;
`;

const FixedSizeSelect = styled(Select)`
  &&& {
    width: 320px;
  }
`;
