import { Button, Card, Descriptions, Form, Input, Modal, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import styled from 'styled-components';

import { companiesApi, usersApi } from '../../api/apiClient';
import { formatInactiveFrom, formatInactiveProcessAt } from '../../components/Date/InactiveFrom';
import { Plan } from '../../generated-api';
import { notifyError, notifySuccess } from '../../utils/notification';
import CompanyPlansHistory from './CompanyPlansHistory/CompanyPlansHistory';

type FormValue = {
  plan_id: string;
};

type Param = {
  id: string;
};
const InactivatedCompanyEdit = () => {
  const [form] = useForm<FormValue>();
  const history = useHistory();
  const param: Param = useParams();
  const companyId = param.id;

  const { value } = useAsync(async () => {
    const { data: company } = await companiesApi.companyControllerGetCompany(companyId);
    const [plansRes, representativeUserRes, companyCurrentPlanRes, companyPlansRes] = await Promise.all([
      companiesApi.companyControllerGetPlanList().catch(() => null),
      usersApi.userControllerGetCompanyUser(company.representative_user_id).catch(() => null),
      companiesApi.companyControllerGetPlan(company.company_id).catch(() => null),
      companiesApi.companyControllerGetCompanyPlan(company.company_id).catch(() => null),
    ]);

    return {
      plans: plansRes?.data.reduce<Record<string, Plan>>((acc, curr) => ({ ...acc, [curr.plan_id]: curr }), {}),
      company,
      representativeUser: representativeUserRes?.data,
      companyCurrentPlan: companyCurrentPlanRes?.data,
      companyPlans: companyPlansRes?.data?.companyPlans,
    };
  }, []);

  const pageBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const onFinish = useCallback(
    async (formValue: FormValue) => {
      const plan = value?.plans?.[formValue.plan_id];
      Modal.confirm({
        title: '以下の組織の復活処理をします',
        content: (
          <Descriptions column={1}>
            <Descriptions.Item label="組織名">{value?.company?.company_name}</Descriptions.Item>
            <Descriptions.Item label="プラン名">{plan?.plan_name}</Descriptions.Item>
          </Descriptions>
        ),
        okText: '復活処理をする',
        cancelText: 'キャンセル',
        width: 800,
        onOk: async () => {
          try {
            await companiesApi.companyControllerActivateCompany(companyId, { plan_id: formValue.plan_id });
            notifySuccess('組織の再登録処理を行いました。');
            history.push('/');
          } catch (error) {
            notifyError('組織の登録に失敗しました。');
          }
        },
      });
    },
    [companyId, history, value?.company?.company_name, value?.plans]
  );

  return (
    <Container>
      <Heading>
        <PageTitle>退会した組織編集</PageTitle>
        <Buttons>
          <Button type="primary" onClick={form.submit}>
            復活
          </Button>
          <Button danger type="primary" onClick={pageBack}>
            キャンセル
          </Button>
        </Buttons>
      </Heading>
      <Content>
        <Card>
          {value != null && (
            <Form
              form={form}
              labelCol={{ span: 8 }}
              labelWrap={true}
              layout="horizontal"
              wrapperCol={{ span: 18 }}
              onFinish={onFinish}>
              <Form.Item initialValue={value.company.company_name} label="組織名" name="company_name">
                <Input disabled type="text" />
              </Form.Item>
              <Form.Item initialValue={value.company.company_rpa_id} label="顧客番号" name="company_rpa_id">
                <Input disabled type="text" />
              </Form.Item>
              <Form.Item initialValue={value.company.address} label="住所" name="address">
                <Input disabled type="text" />
              </Form.Item>
              <Form.Item
                initialValue={formatInactiveFrom(value.company.inactive_from)}
                label="退会日"
                name="inactive_from">
                <Input disabled type="text" />
              </Form.Item>
              <Form.Item
                initialValue={formatInactiveProcessAt(value.company.inactive_process_at)}
                label="退会処理日"
                name="inactive_process_at">
                <Input disabled type="text" />
              </Form.Item>
              <Form.Item
                initialValue={value.companyCurrentPlan?.plan_id}
                label="利用プラン"
                name="plan_id"
                rules={[{ required: true, message: 'プランを選択してください。' }]}>
                <Select>
                  {value.plans &&
                    Object.values(value.plans)?.map((plan) => {
                      return (
                        <Select.Option key={plan.plan_id} value={plan.plan_id}>
                          {plan.plan_name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item initialValue={value.representativeUser?.name} label="担当者名" name="representative_user_name">
                <Input disabled type="text" />
              </Form.Item>
              <Form.Item
                initialValue={value.representativeUser?.email}
                label="担当者メールアドレス"
                name="representative_user_email">
                <Input disabled type="text" />
              </Form.Item>
            </Form>
          )}
        </Card>
      </Content>
      <TableContainer>
        <CompanyPlansHistory companyPlans={value?.companyPlans ?? null} plans={value?.plans ?? null} />
      </TableContainer>
    </Container>
  );
};

export default InactivatedCompanyEdit;

const Container = styled.div`
  width: 80%;
  padding: 24px;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #5a89d0;
  padding-right: 40px;
`;

const PageTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: #5a89d0;
`;

const Buttons = styled(Space)`
  align-items: unset;
`;

const Content = styled.div`
  width: 90%;
  margin-left: 24px;
  margin-top: 36px;
`;

const TableContainer = styled.div`
  margin: 10px 20px;
`;
