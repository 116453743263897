import { Input } from 'antd';
import { ReactElement } from 'react';

import FormItemWithLabel from '../../../components/Form/FormItemWithLabel';
import { DocumentWithholdingSlipEntity } from '../../../generated-api';
import renderTableColumn from './renderTableColumn';
import { OcrResultPageDefinition } from './types';

interface Props {
  entity: DocumentWithholdingSlipEntity;
}
function WithholdingSlipFormInputs({ entity }: Props): ReactElement {
  return (
    <>
      <FormItemWithLabel label="受給者氏名">
        <Input disabled defaultValue={entity.earner_name} />
      </FormItemWithLabel>
      <FormItemWithLabel label="受給者番号">
        <Input disabled defaultValue={entity.earner_number} />
      </FormItemWithLabel>
      <FormItemWithLabel label="受給者住所">
        <Input disabled defaultValue={entity.earner_address} />
      </FormItemWithLabel>
      <FormItemWithLabel label="生年月日">
        <Input disabled defaultValue={entity.date_of_birth} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者氏名">
        <Input disabled defaultValue={entity.employer_name} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者個人番号/法人番号 " marginBottom="1em">
        <Input disabled defaultValue={entity.employer_number} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者住所">
        <Input disabled defaultValue={entity.employer_address} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者電話番号" marginBottom="1em">
        <Input disabled defaultValue={entity.employer_phone} />
      </FormItemWithLabel>
      <FormItemWithLabel label="種別">
        <Input disabled defaultValue={entity.income_type} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払金額">
        <Input disabled defaultValue={entity.payment_amount} />
      </FormItemWithLabel>
      <FormItemWithLabel label="源泉徴収税額">
        <Input disabled defaultValue={entity.withholding_amount} />
      </FormItemWithLabel>
      <FormItemWithLabel label="社会保険料">
        <Input disabled defaultValue={entity.social_insurance} />
      </FormItemWithLabel>
      <FormItemWithLabel label="生命保険料の控除額" marginBottom="1em">
        <Input disabled defaultValue={entity.life_insurance_deduction} />
      </FormItemWithLabel>
      <FormItemWithLabel label="地震保険料の控除額" marginBottom="1em">
        <Input disabled defaultValue={entity.earthquake_insurance_deduction} />
      </FormItemWithLabel>
      <FormItemWithLabel label="住宅借入金等特別控除額" marginBottom="1em">
        <Input disabled defaultValue={entity.house_loan_deduction} />
      </FormItemWithLabel>
      <FormItemWithLabel label="その他">
        <Input disabled defaultValue={entity.other} />
      </FormItemWithLabel>
    </>
  );
}

const withholdingSlipResultPageDefinition: OcrResultPageDefinition<DocumentWithholdingSlipEntity> = {
  name: '(ベータ版)源泉徴収票',
  tableColumns: [
    {
      dataIndex: ['latest_revision', 'earner_name'],
      title: '受給者氏名',
      render: renderTableColumn,
    },
    { dataIndex: ['latest_revision', 'payment_amount'], title: '支払金額', render: renderTableColumn },
    {
      dataIndex: ['latest_revision', 'withholding_amount'],
      title: '源泉徴収税額',
      render: renderTableColumn,
    },
    {
      dataIndex: ['latest_revision', 'employer_name'],
      title: '支払者氏名',
      render: renderTableColumn,
    },
  ],
  formComponent: WithholdingSlipFormInputs,
};

export default withholdingSlipResultPageDefinition;
