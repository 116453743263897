import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { memo, useEffect } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import styled from 'styled-components';

import { documentApi } from '../../api/apiClient';
import useAPIData from '../../hooks/useAPIData';
import LoadingIcon from '../Loading/LoadingIcon';
type Props = {
  groupId: string;
  documentId: string;
};

const imageHeight = '80vh';

const DocumentImage = ({ documentId, groupId }: Props) => {
  const {
    data: previewUrl,
    error,
    reset,
  } = useAPIData(async () => {
    const { data } = await documentApi.documentControllerGetDividedImagePresignedUrl(groupId, documentId);

    return data.image_url;
  });

  useEffect(() => {
    reset();
  }, [documentId, groupId, reset]);

  return (
    <TransformWrapper limitToBounds={false} panning={{ velocityDisabled: true }}>
      {({ resetTransform, zoomIn, zoomOut }) => (
        <>
          <Buttons>
            <Button onClick={() => zoomIn()}>
              <ZoomInOutlined />
            </Button>
            <Button onClick={() => zoomOut()}>
              <ZoomOutOutlined />
            </Button>
            <Button onClick={() => resetTransform()}>RESET</Button>
          </Buttons>
          <TransformComponent wrapperStyle={wrapperStyle}>
            {!error ? (
              <ImageContainer>
                {previewUrl == null ? <LoadingIcon style={{ padding: '24px' }} /> : <Image src={previewUrl ?? ''} />}
              </ImageContainer>
            ) : (
              <OcrErrorContainer>読み取り中にエラーが発生したため画像は表示されません。</OcrErrorContainer>
            )}
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
};

export default memo(DocumentImage);
const wrapperStyle = {
  width: '100%',
  border: '1px solid #f0f0f0',
  marginRight: '8px',
  overflow: 'auto',
};

const Buttons = styled(Space)`
  width: 100%;
  margin: 4px 0 8px;
`;

const ImageContainer = styled.div`
  height: ${imageHeight};
  :hover {
    cursor: grab;
  }
`;

const OcrErrorContainer = styled.div`
  height: ${imageHeight};
  padding: 24px;
`;

const Image = styled.img`
  width: 100%;
`;
