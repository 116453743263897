import { format } from 'date-fns';

type Props = {
  value: string | Date | undefined;
  className?: string;
};

export default function InactiveFrom({ className, value }: Props) {
  return <span className={className}>{formatInactiveFrom(value) ?? '-'}</span>;
}

export function formatInactiveFrom(dateStr: string | Date | undefined) {
  const date = dateStr ? dateParse(dateStr) : null;
  return date != null && !isNaN(date.getTime()) ? format(date, 'yyyy年MM月dd日') : undefined;
}

export function formatInactiveProcessAt(dateStr: string | Date | undefined) {
  return formatInactiveFrom(dateStr);
}

function dateParse(date: Date | string) {
  return date instanceof Date ? date : new Date(date);
}
