import { Button, Form, FormInstance, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import LoadingIcon from '../../components/Loading/LoadingIcon';

type AdminUserFormType = {
  name: string;
  email: string;
};

type Props = {
  form: FormInstance<AdminUserFormType>;
  onFinish: (form: AdminUserFormType) => void;
  initialValue: AdminUserFormType | null;
};

const AdminUserForm = ({ form, initialValue, onFinish }: Props) => {
  const history = useHistory();

  return !initialValue ? (
    <LoadingIcon />
  ) : (
    <Form
      form={form}
      labelAlign="left"
      labelCol={{ span: 6 }}
      labelWrap={true}
      wrapperCol={{ span: 18 }}
      onFinish={onFinish}>
      <Form.Item
        initialValue={initialValue.name}
        label="名前"
        name="name"
        rules={[{ required: true, message: '名前を入力してください。' }]}>
        <TextInput type="text" />
      </Form.Item>
      <Form.Item
        initialValue={initialValue.email}
        label="メールアドレス"
        name="email"
        rules={[{ required: true, type: 'email', message: 'メールアドレスを入力してください。' }]}>
        <TextInput type="text" />
      </Form.Item>
      <div>
        <RegisterButton type="primary" onClick={form.submit}>
          登録
        </RegisterButton>
        <Button type="default" onClick={() => history.push('/users/')}>
          キャンセル
        </Button>
      </div>
    </Form>
  );
};

export default AdminUserForm;

const RegisterButton = styled(Button)`
  margin-right: 10px;
`;

const TextInput = styled(Input)`
  width: 320px;
`;
