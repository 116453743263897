import { Descriptions, Form, Input, Modal, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { UserRole } from '../../generated-api';

const rule = [{ required: true, message: 'この項目は必須です' }];

export type CreateUserValue = {
  email: string;
  name: string;
  role: UserRole;
};

type Props = {
  onSubmit: (values: CreateUserValue) => Promise<void>;
  onCancel: () => void;
};

export default function CreateUserModal({ onCancel, onSubmit }: Props) {
  const [form] = useForm<CreateUserValue>();
  return (
    <Modal
      visible
      cancelText="キャンセル"
      maskClosable={false}
      okText="確認"
      title="新規ユーザ登録"
      onCancel={onCancel}
      onOk={() => form.submit()}>
      <Form
        form={form}
        style={{ userSelect: 'none' }}
        onFinish={(value) => {
          Modal.confirm({
            title: '以下の内容でこの組織にユーザを登録します',
            width: '450px',
            content: (
              <Descriptions column={1}>
                <Descriptions.Item label="メールアドレス">{value.email}</Descriptions.Item>
                <Descriptions.Item label="ユーザ名">{value.name}</Descriptions.Item>
                <Descriptions.Item label="権限">{value.role === 'admin' ? '管理者' : '一般'}</Descriptions.Item>
              </Descriptions>
            ),
            okText: 'ユーザ登録',
            cancelText: '修正する',
            onOk: async () => onSubmit(value),
          });
        }}>
        <Form.Item
          required
          label="メールアドレス"
          name="email"
          rules={[...rule, { type: 'email', message: '有効なメールアドレスを入力してください' }]}>
          <Input />
        </Form.Item>
        <Form.Item required label="ユーザ名" name="name" rules={rule}>
          <Input />
        </Form.Item>
        <Form.Item required label="権限" name="role" rules={rule}>
          <Radio.Group>
            <Radio value={UserRole.Member}>一般ユーザ</Radio>
            <Radio value={UserRole.Admin}>管理者ユーザ</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}
