import { Card } from 'antd';
import Title from 'antd/lib/typography/Title';
import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { documentApi } from '../../api/apiClient';
import parseFilePath from '../../components/Lib/parseFilePath';
import UserName from '../../components/User/UserName';
import { DocumentType, GetDocumentGroupResponse } from '../../generated-api';
import useInterval from '../../hooks/useInterval';
import useLifecycles from '../../hooks/useLifecycles';
import ocrResultPageDefinitions from './defs';
import { ResultPageDocument } from './defs/types';
import DocumentEditModal from './DocumentEditModal';
import DocumentStatus from './DocumentStatus';
import ResultTable from './ResultTable';

const SummaryContainer = styled.div`
  width: 100%;
`;
const ImplementationInfo = styled.div`
  margin-bottom: 16px;
`;
const ImplementationInfoItem = styled.div``;
const FileCount = styled.div``;
const PageCountContainer = styled.div``;
const PageCountTotal = styled.div``;
const PageCountList = styled.ul`
  padding: 0;
  display: flex;
`;
const PageCountListElement = styled.li`
  list-style-type: none;
  margin-right: 10px;
`;

type Params = {
  type: DocumentType;
  group: string;
};

const OcrResultPage = ({ match }: RouteComponentProps<Params>) => {
  const groupId = match.params.group;
  const type = match.params.type;

  const [editingDocumentId, setEditingDocumentId] = useState<string | undefined>(undefined);

  const { completed, documentGroup, failedFiles, fileCount, results, succeededFiles, totalPages, updateList } =
    usePrepareResultPage(groupId);

  const closeEditor = useCallback(() => {
    setEditingDocumentId(undefined);
    updateList();
  }, [updateList]);

  const uploadedAt = documentGroup?.uploaded_at
    ? format(parseISO(documentGroup?.uploaded_at ?? ''), 'yyyy-MM-dd HH:mm:ss')
    : '';

  return (
    <Card>
      <SummaryContainer>
        <Title level={5}>書類種別: {ocrResultPageDefinitions[type].name}</Title>
        <DocumentStatus completed={completed} />
        <ImplementationInfo>
          <ImplementationInfoItem>
            実施者: <UserName userId={documentGroup?.user_id ?? ''} />
          </ImplementationInfoItem>
          <ImplementationInfoItem>実施日時: {uploadedAt}</ImplementationInfoItem>
        </ImplementationInfo>
        <FileCount>ファイル数: {fileCount}</FileCount>
        <PageCountContainer>
          <PageCountTotal>総ページ数: {totalPages > 0 ? totalPages : '-'}</PageCountTotal>
          <PageCountList>
            <PageCountListElement>正常ファイル数: {succeededFiles}</PageCountListElement>
            <PageCountListElement>エラーファイル数: {failedFiles}</PageCountListElement>
          </PageCountList>
        </PageCountContainer>
      </SummaryContainer>
      <ResultTable results={results} type={type} updateList={updateList} onClickModalOpen={setEditingDocumentId} />

      {editingDocumentId != null && (
        <DocumentEditModal
          documentId={editingDocumentId}
          groupId={groupId}
          results={results}
          onClose={closeEditor}
          onNavigate={setEditingDocumentId}
          onUpdated={updateList}
        />
      )}
    </Card>
  );
};

export default OcrResultPage;

function usePrepareResultPage(groupId: string) {
  const [documentGroup, setDocumentGroup] = useState<GetDocumentGroupResponse | undefined>(undefined);
  const [fileCount, setFileCount] = useState(-1);
  const [totalPages, setTotalPages] = useState(-2);
  const [succeededFiles, setSucceededFiles] = useState(-1);
  const [failedFiles, setFailedFiles] = useState(-1);
  const [results, setResults] = useState<ResultPageDocument[]>([]);

  const updateList = async () => {
    const { data: documentGroup } = await documentApi.documentControllerGetDocumentGroupById(groupId);
    const ocrResults = await fetchDocuments(groupId);

    setDocumentGroup(documentGroup);
    setResults(ocrResults);
    setTotalPages(ocrResults.length);
    setSucceededFiles(documentGroup.completed_source_bucket_keys.length);
    setFailedFiles(documentGroup.errors_source_bucket_keys.length);
  };

  const [start, clear, timerRunning] = useInterval(updateList, 3000);

  useLifecycles(() => {
    (async () => {
      const { data } = await documentApi.documentControllerGetDocumentGroupById(groupId);
      setFileCount(data.files_count);
      start();
    })();
  });

  useEffect(() => {
    if (timerRunning && fileCount <= succeededFiles + failedFiles) {
      clear();
    }
  }, [clear, fileCount, start, succeededFiles, failedFiles, timerRunning]);

  return {
    documentGroup,
    fileCount,
    totalPages,
    succeededFiles,
    failedFiles,
    results,
    updateList,
    completed: !timerRunning && fileCount <= succeededFiles + failedFiles,
  };
}

async function fetchDocuments(groupId: string): Promise<ResultPageDocument[]> {
  const { data: documents } = await documentApi.documentControllerGetDocuments(groupId);

  return documents
    .sort((lhs, rhs) => lhs.user_file_path.localeCompare(rhs.user_file_path) || (lhs.page ?? -1) - (rhs.page ?? -1))
    .map((document) => ({ ...document, parsed_path: parseFilePath(document.user_file_path) }));
}
