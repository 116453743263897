import { DatePicker, Descriptions, Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { addMonths, startOfMonth } from 'date-fns';
import { type Moment } from 'moment';

import InactiveFrom from '../../components/Date/InactiveFrom';
import locale from '../../utils/datePickerLocale';

export type InactivateCompanyModalValue = {
  date: Date;
};

type Props = {
  companyName: string;
  onSubmit: (value: InactivateCompanyModalValue) => void;
  onCancel: () => void;
};

export default function InactivateCompanyModal({ companyName, onCancel, onSubmit }: Props) {
  const [form] = useForm();
  const now = new Date();
  return (
    <Modal
      visible
      cancelText="閉じる"
      okButtonProps={{ type: 'primary', danger: true }}
      okText="確認"
      title="退会手続き"
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}>
      <Form
        form={form}
        onFinish={({ date: dateValue }: { date: Moment }) => {
          Modal.confirm({
            title: '以下の内容で退会処理を行います。',
            content: (
              <Descriptions colon={false} column={1}>
                <Descriptions.Item label="組織名">{companyName}</Descriptions.Item>
                <Descriptions.Item label="退会日">
                  <InactiveFrom value={startOfMonth(dateValue.toDate())} />
                </Descriptions.Item>
              </Descriptions>
            ),
            onOk: () => onSubmit({ date: dateValue.toDate() }),
            cancelText: '閉じる',
            okButtonProps: { type: 'primary', danger: true },
            okText: '退会',
          });
        }}>
        <Descriptions column={1}>
          <Descriptions.Item>退会月を指定してください。</Descriptions.Item>
          <Descriptions.Item style={{ fontWeight: 'bold' }}>※ 指定した月の1日より退会となります。</Descriptions.Item>
        </Descriptions>

        <Form.Item name="date" rules={[{ required: true, message: '日付を選択してください。' }]}>
          <DatePicker.MonthPicker
            disabledDate={(currentDate) => currentDate.isBefore(addMonths(now, 1))}
            locale={locale}
            placeholder="退会年月"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
