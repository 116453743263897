import { Input, InputNumber } from 'antd';
import { ReactElement } from 'react';

import FormItemWithLabel from '../../../components/Form/FormItemWithLabel';
import { DocumentMedicalBillEntity } from '../../../generated-api';
import renderTableColumn from './renderTableColumn';
import { OcrResultPageDefinition } from './types';

interface Props {
  entity: DocumentMedicalBillEntity;
}
function MedicalBillFormInputs({ entity }: Props): ReactElement {
  return (
    <>
      <FormItemWithLabel label="日付">
        <Input disabled defaultValue={entity.date} />
      </FormItemWithLabel>
      <FormItemWithLabel label="金額">
        <InputNumber disabled defaultValue={entity.amount} />
      </FormItemWithLabel>
      <FormItemWithLabel label="住所">
        <Input disabled defaultValue={entity.address} />
      </FormItemWithLabel>
      <FormItemWithLabel label="医療機関名">
        <Input disabled defaultValue={entity.hospital_name} />
      </FormItemWithLabel>
      <FormItemWithLabel label="受診者名">
        <Input disabled defaultValue={entity.patient_name} />
      </FormItemWithLabel>
    </>
  );
}

const medicalBillResultPageDefinition: OcrResultPageDefinition<DocumentMedicalBillEntity> = {
  name: '医療費領収書',
  formComponent: MedicalBillFormInputs,
  tableColumns: [
    { dataIndex: ['latest_revision', 'date'], title: '日付', render: renderTableColumn },
    { dataIndex: ['latest_revision', 'amount'], title: '金額', render: renderTableColumn },
    { dataIndex: ['latest_revision', 'address'], title: '住所', render: renderTableColumn },
    { dataIndex: ['latest_revision', 'hospital_name'], title: '医療機関名', render: renderTableColumn },
    { dataIndex: ['latest_revision', 'patient_name'], title: '受診者名', render: renderTableColumn },
  ],
};
export default medicalBillResultPageDefinition;
