import { List } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const pageData = [
  { path: '/', title: '組織管理' },
  { path: '/documents/search', title: '過去読み取り結果' },
  { path: '/usage/', title: '組織別利用料確認' },
  { path: '/usage/output', title: '年月別利用量出力' },
  { path: '/users/', title: '管理者ユーザ' },
  { path: '/mail/', title: 'メール送信' },
];

const Sidebar = () => {
  return (
    <Container>
      <Children>
        <List
          dataSource={pageData}
          renderItem={(item) => (
            <List.Item>
              <PageLink to={item.path}>{item.title}</PageLink>
            </List.Item>
          )}
        />
      </Children>
    </Container>
  );
};

export default Sidebar;

const Container = styled(Sider)`
  background-color: #f0f0f0;
  padding: 16px 24px;
`;

const Children = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PageLink = styled(Link)`
  color: #000;
`;
