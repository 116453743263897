import { Form } from 'antd';
import { ReactElement } from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  text-align: left;
  width: 5.5rem;
  white-space: pre-wrap;
`;

interface Props {
  label: string;
  children: ReactElement;
  labelWidth?: string;
  marginBottom?: string;
}
const FormItemWithLabel = ({ children, label, labelWidth, marginBottom }: Props) => {
  return (
    <Form.Item
      label={<StyledLabel style={{ width: labelWidth, marginBottom: `${marginBottom}` }}>{label}</StyledLabel>}>
      {children}
    </Form.Item>
  );
};

export default FormItemWithLabel;
