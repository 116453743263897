import { Table } from 'antd';

import { CompanyPlan, Plan } from '../../../generated-api';

type Props = {
  companyPlans: CompanyPlan[] | null;
  plans: Record<string, Plan> | null;
};

export default function CompanyPlansHistory({ companyPlans, plans }: Props) {
  return (
    <Table
      columns={[
        {
          title: 'プラン有効日',
          dataIndex: ['since'],
          key: 'since',
          render: (since: string) => `${since}~`,
        },
        {
          title: '利用プラン',
          dataIndex: ['plan_id'],
          key: 'plan_id',
          render: (planId: string) => plans?.[planId]?.plan_name ?? `不明なプラン(ID:${planId})`,
        },
      ]}
      dataSource={companyPlans ?? []}
      loading={companyPlans == null}
      title={() => 'プラン履歴'}
    />
  );
}
