import { Button, Card, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ComponentType, ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';

import FormItemWithLabel from '../../components/Form/FormItemWithLabel';
import ocrResultPageDefinitions from './defs';
import { DocumentEntities, FormComponentProps, ResultPageDocument } from './defs/types';

const StyledCard = styled(Card)`
  flex: 1 1;
  display: flex;
  flex-direction: column;

  > .ant-card-body {
    display: flex;
    flex-direction: column;
  }
`;

const ResultLatestButtons = styled.div`
  margin-bottom: 16px;
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

interface Props {
  document: ResultPageDocument;
  result: ResultPageDocument<DocumentEntities>;
  onClose: () => void;
}

export default function DocumentEditCard(props: Props): ReactElement {
  const { document, onClose, result } = props;
  const [entity, setEntity] = useState(result.latest_revision);

  const [form] = useForm<DocumentEntities>();

  const initialEntity = useCallback(() => {
    setEntity(result.ocr_result);
  }, [result.ocr_result]);

  const latestEntity = useCallback(() => {
    setEntity(result.latest_revision);
  }, [result.latest_revision]);

  const FormComponent = ocrResultPageDefinitions[result.latest_revision.type].formComponent as ComponentType<
    FormComponentProps<DocumentEntities>
  >;
  return (
    <StyledCard>
      <Form form={form}>
        <ResultLatestButtons>
          <StyledButton onClick={initialEntity}>修正前</StyledButton>
          <StyledButton onClick={latestEntity}>修正後</StyledButton>
        </ResultLatestButtons>
        <FormItemWithLabel label="ファイル名">
          <Input disabled value={document.parsed_path.fullFileName} />
        </FormItemWithLabel>
        <FormItemWithLabel label="ページ数">
          <Input disabled value={document.page} />
        </FormItemWithLabel>
        <FormComponent entity={entity} />
        <Form.Item hidden name="type">
          <Input />
        </Form.Item>
      </Form>
      <CloseButton>
        <StyledButton onClick={onClose}>閉じる</StyledButton>
      </CloseButton>
    </StyledCard>
  );
}
