import { useEffect } from 'react';

function useLifecycles(callback: () => void | (() => void)): void {
  useEffect(() => {
    const cleanup = callback();
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useLifecycles;
