import styled from 'styled-components';

import { CompanyCreateForm } from './CompanyCreate';

type Props = {
  formContent: CompanyCreateForm;
  planName: string;
};

const CompanyCreateConfirm = ({ formContent, planName }: Props) => {
  return (
    <>
      <Container>
        <p>
          以下の情報で登録しますか？
          <br />
          担当者のアカウントが作成され、入力したアドレスにログインパスワードがメールされます。
        </p>
        <Content>
          <FormRow>
            <FormLabel>組織名</FormLabel>
            <FormValue>{formContent.company_name}</FormValue>
          </FormRow>
          <FormRow>
            <FormLabel>顧客番号</FormLabel>
            <FormValue>{formContent.company_rpa_id}</FormValue>
          </FormRow>
          <FormRow>
            <FormLabel>利用プラン</FormLabel>
            <FormValue>{planName}</FormValue>
          </FormRow>
          <FormRow>
            <FormLabel>住所</FormLabel>
            <FormValue>{formContent.address}</FormValue>
          </FormRow>
          <FormRow>
            <FormLabel>担当者名</FormLabel>
            <FormValue>{formContent.representative_user_name}</FormValue>
          </FormRow>
          <FormRow>
            <FormLabel>担当者メールアドレス</FormLabel>
            <FormValue>{formContent.representative_user_email}</FormValue>
          </FormRow>
        </Content>
      </Container>
    </>
  );
};

export default CompanyCreateConfirm;

const Container = styled.div`
  width: 90%;
  margin: auto;
`;

const Content = styled.div`
  margin: auto;
  padding: 5px;
  border: 1px solid;
  border-color: black;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
`;

const FormLabel = styled.div`
  width: 30%;
  padding: 5px;
`;

const FormValue = styled.div`
  width: 70%;
  padding: 5px;
  border: 1px solid;
  border-color: black;
`;
