import { ReactElement } from 'react';
import { useAsync } from 'react-use';

import usersDataLoader from '../../api/usersDataLoader';
import LoadingDot from '../Loading/LoadingDot';

interface Props {
  userId: string;
}
const UserName = ({ userId }: Props): ReactElement => {
  const { loading, value: userName } = useAsync(async () => {
    const user = await usersDataLoader.getUserById(userId);
    return user?.name;
  }, [userId]);

  return <>{loading ? <LoadingDot /> : userName ?? '不明なユーザ'}</>;
};

export default UserName;
