import { DocumentType } from '../../../generated-api';

const ocrTypeNameDefinitions: Record<DocumentType, string> = {
  furusato_tax: 'ふるさと納税',
  medical_bill: '医療費領収書',
  bankbook: '通帳',
  card_statement: 'カード明細',
  receipt: '(ベータ版)領収書',
  payment_record: '(ベータ版)支払い調書',
  dual_horizontal_receipt: '(ベータ版)領収書',
  dual_vertical_receipt: '(ベータ版)領収書',
  withholding_slip: '(ベータ版)源泉徴収票',
  general_table: '(ベータ版)表データ',
  ocr_error: '読み取りエラー',
};
export default ocrTypeNameDefinitions;
