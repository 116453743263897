import styled from 'styled-components';

type AdminUserFormType = {
  name: string;
  email: string;
};

type Props = {
  formContent: AdminUserFormType;
};

const AdminUserCreateConfirm = ({ formContent }: Props) => {
  return (
    <>
      <Container>
        <p>
          ユーザー{formContent.name}を作成しますか？ 登録したメールアドレスに以下のログイン情報をお送りいたします。
          <br />
          担当者のアカウントが作成され、入力したアドレスにログインパスワードがメールされます。
        </p>
        <Content>
          <AddressLabel>メールアドレス</AddressLabel>
          <p>{formContent.email}</p>
        </Content>
      </Container>
    </>
  );
};

export default AdminUserCreateConfirm;

const Container = styled.div`
  width: 90%;
  margin: auto;
`;

const Content = styled.div`
  margin: auto;
  padding: 5px;
  display: flex;
  flex-direction: row;
`;

const AddressLabel = styled.div`
  margin-right: 32px;
`;
