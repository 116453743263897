import { Button, Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { authApi } from '../../api/apiClient';
import AuthPageContainer from '../../components/Container/AuthPageContainer';

const PasswordForgetPage = () => {
  const [form] = useForm();
  const history = useHistory();

  const send = useCallback(async () => {
    const email = form.getFieldValue('email');
    await authApi.authControllerSendPasswordChangeMail({ email });
    history.push('/');
  }, [form, history]);

  const emailRules: Rule[] = useMemo(
    () => [
      { required: true, message: '入力必須項目' },
      { type: 'email', message: '有効なメールアドレスを入力してください' },
    ],
    []
  );

  return (
    <AuthPageContainer>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Description>
          <Sentence>登録したメールアドレスを入力し、送信ボタンを押してください。</Sentence>
          <Sentence>入力したメールアドレスにメールが送信されます。</Sentence>
          <Sentence>
            こちらのメールに記載されたパスワード変更用のリンクにアクセスし、パスワードの変更をお願いいたします。
          </Sentence>
        </Description>
        <FormItem label="メールアドレス" name="email" rules={emailRules}>
          <FormInput type="email" />
        </FormItem>
        <FormItem>
          <FormButton block htmlType="submit" type="primary" onClick={send}>
            メール送信
          </FormButton>
        </FormItem>
      </Form>
    </AuthPageContainer>
  );
};

export default PasswordForgetPage;

const FormItem = styled(Form.Item)`
  width: 500px;
`;

const FormInput = styled(Input)`
  height: 45px;
`;

const Description = styled.div`
  margin-bottom: 24px;
`;

const Sentence = styled.p`
  width: 500px;
  margin: 0;
  color: #777;
  word-break: keep-all;
`;

const FormButton = styled(Button)`
  height: 50px;
`;
