import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { ReactElement, ReactNode } from 'react';

import { DocumentCardStatementEntity } from '../../../generated-api';
import ensureDefined from '../../../utils/ensureDefined';
import { OcrResultPageDefinition, ResultPageDocument } from './types';

interface Props {
  entity: DocumentCardStatementEntity;
}
function CardStatementFormInput({ entity }: Props): ReactElement {
  return <ExpandedCardStatementTable record={entity} />;
}

function wrapExpandedCardStatementTable(record: ResultPageDocument<DocumentCardStatementEntity>): ReactNode {
  return !record.error ? <ExpandedCardStatementTable record={record.latest_revision} /> : <></>;
}
interface ExpandedTableProps {
  record: DocumentCardStatementEntity;
}
function ExpandedCardStatementTable({ record }: ExpandedTableProps): ReactElement {
  const columnNames = ensureDefined(record.column_names);

  const rows = ensureDefined(record.rows);

  const data = rows.map((row) => {
    return row.reduce<Record<string, string>>((accumulator, current, index) => {
      accumulator[columnNames[index]] = current;
      return accumulator;
    }, {});
  });

  return (
    <Table dataSource={data} pagination={false} scroll={{ y: '780px' }}>
      {columnNames.map((name) => {
        return <Column key={name} dataIndex={name} title={name} />;
      })}
    </Table>
  );
}

const cardStatementResultPageDefinition: OcrResultPageDefinition<DocumentCardStatementEntity> = {
  name: 'カード明細',
  tableColumns: [],
  tableExpandable: {
    expandedRowRender: wrapExpandedCardStatementTable,
  },
  formComponent: CardStatementFormInput,
};
export default cardStatementResultPageDefinition;
