import { Form, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import styled from 'styled-components';

import ocrTypeNameDefinitions from '../../page/Search/defs';

interface Props {
  label?: string;
  rules?: Rule[];
  className?: string;
}
export const documentTypeChoice = [
  { value: 'furusato_tax', name: ocrTypeNameDefinitions.furusato_tax },
  { value: 'medical_bill', name: ocrTypeNameDefinitions.medical_bill },
  { value: 'bankbook', name: ocrTypeNameDefinitions.bankbook },
  { value: 'card_statement', name: ocrTypeNameDefinitions.card_statement },
  { value: 'withholding_slip', name: ocrTypeNameDefinitions.withholding_slip },
  { value: 'receipt', name: ocrTypeNameDefinitions.receipt },
  { value: 'payment_record', name: ocrTypeNameDefinitions.payment_record },
  { value: 'general_table', name: ocrTypeNameDefinitions.general_table },
];

const DocumentTypeSelectForm = ({ className, label, rules }: Props) => {
  return (
    <FormItem className={className} label={label} name="ocrType" rules={rules}>
      <Select placeholder="選択してください">
        {documentTypeChoice.map((type) => (
          <Select.Option key={type.value} value={type.value}>
            {type.name}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );
};

export default DocumentTypeSelectForm;

const FormItem = styled(Form.Item)`
  width: 400px;
`;
