import { Button, DatePicker, Form, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { useCallback } from 'react';
import { useAsync } from 'react-use';
import styled from 'styled-components';

import { companiesApi } from '../../api/apiClient';
import DocumentTypeSelectForm from '../../components/Form/DocumentTypeSelectForm';
import { DocumentType } from '../../generated-api';

interface Props {
  updateList: (companyId: string, documentType?: DocumentType, since?: string, until?: string) => void;
}

const rules: Rule[] = [{ required: true, message: 'この項目は必須です' }];

const SearchForm = ({ updateList }: Props) => {
  const [search] = useForm();
  const { value: companies } = useAsync(async () => {
    const { data } = await companiesApi.companyControllerGetAllCompanyList();
    return data;
  });

  const onSearch = useCallback(() => {
    const documentType = search.getFieldValue('ocrType');
    const companyId = search.getFieldValue('company');
    const date = search.getFieldValue('date');
    /*
    DBに登録されているレコードのuploaded_at(YYYY-MM-DDTHH:mm:ss.SSSZ)がUTCのため、
    JSTが 2022-06-17T00:00:00.000 ~ 2022-06-17T23:59:59.999 の場合、
    UTCは 2022-06-16T15:00:00.000 ~ 2022-06-17T14:59:59.999 となる
    */
    const since = date && date[0].subtract(1, 'd').format('YYYY-MM-DD').toString() + 'T15:00:00.000';
    const until = date && date[1].format('YYYY-MM-DD').toString() + 'T14:59:59.999';
    updateList(companyId, documentType, since, until);
  }, [search, updateList]);

  const onReset = useCallback(() => search.resetFields(), [search]);

  return (
    <FormContainer form={search} layout="vertical" onFinish={onSearch}>
      <FormItem label="組織の選択" name="company" rules={rules}>
        <Select showSearch optionFilterProp="children" placeholder="選択してください(選択必須)">
          {companies &&
            companies.map((company) => (
              <Select.Option key={company.company_id} value={company.company_id}>
                {company.company_name}
              </Select.Option>
            ))}
        </Select>
      </FormItem>
      <FormItem label="実施日" name="date">
        <DatePicker.RangePicker allowClear={false} />
      </FormItem>
      <DocumentTypeSelect label="帳票の選択" />
      <FormItem>
        <Button htmlType="submit" size="large" type="primary">
          検索
        </Button>
        <ResetButton size="large" onClick={onReset}>
          リセット
        </ResetButton>
      </FormItem>
    </FormContainer>
  );
};

export default SearchForm;

const FormContainer = styled(Form)`
  display: flex;
  border: gray solid 1px;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 24px;
  gap: 8px 48px;
  flex-wrap: wrap;
  align-items: flex-end;
`;

const FormItem = styled(Form.Item)`
  width: calc(50% - 48px);
`;

const DocumentTypeSelect = styled(DocumentTypeSelectForm)`
  width: calc(50% - 48px);
`;

const ResetButton = styled(Button)`
  margin-left: 8px;
`;
