import { Button, Modal } from 'antd';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import DocumentImage from '../../components/Container/DocumentImage';
import { Document } from '../../generated-api';
import { ResultPageDocument } from './defs/types';
import DocumentEditCard from './DocumentEditCard';

const Container = styled.div`
  display: flex;
`;
const UserInteractiveSide = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
`;
const NavigateButtons = styled.div`
  margin: 4px 0 8px;
  display: flex;
  flex: 0 0;
  justify-content: space-between;
`;
const ImageSide = styled.div`
  width: 60%;
  margin-right: 8px;
`;

type Props = {
  results: ResultPageDocument[];
  documentId?: string;
  groupId: string;
  onNavigate: (documentId: string) => void;
  onUpdated: (document: Document) => void;
  onClose: () => void;
};

async function openDiscardConfirmModal(): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    Modal.confirm({
      title: '内容が変更されています。',
      content: '変更を破棄しますか？',
      okText: '破棄',
      okButtonProps: { type: 'primary', danger: true },
      cancelText: '続行',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });
}

const DocumentEditModal = (props: Props): ReactElement | null => {
  const { documentId, groupId, onClose, onNavigate, results } = props;

  const [changed, setChanged] = useState(false);

  const currentIndex = useMemo(() => {
    const index = results.findIndex((item) => item.document_id === documentId);

    // -1 のまま返すと DocumentEditModal の hasNext を計算するときに困る
    // null を返すと型チェックが面倒になる
    // よって、見つからないときは -2 を返す
    return index === -1 ? -2 : index;
  }, [documentId, results]);

  const confirmDiscard = useCallback(async (): Promise<boolean> => {
    if (changed) {
      const result = await openDiscardConfirmModal();
      if (!result) {
        return false;
      }
      setChanged(false);
    }
    return true;
  }, [changed]);

  const next = results[currentIndex + 1];
  const prev = results[currentIndex - 1];
  const toNext = useCallback(async () => {
    if (next && (await confirmDiscard())) {
      onNavigate(next.document_id);
    }
  }, [confirmDiscard, next, onNavigate]);
  const toPrev = useCallback(async () => {
    if (prev && (await confirmDiscard())) {
      onNavigate(prev.document_id);
    }
  }, [confirmDiscard, onNavigate, prev]);
  const close = useCallback(async () => {
    if (await confirmDiscard()) {
      onClose();
    }
  }, [confirmDiscard, onClose]);

  if (documentId == null) {
    return null;
  }
  return (
    <Modal
      centered
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={null}
      maskClosable={false}
      okButtonProps={{ style: { display: 'none' } }}
      style={{ minWidth: '700px' }}
      visible={true}
      width="90vw"
      onCancel={onClose}>
      <Container>
        <ImageSide>
          <DocumentImage documentId={documentId} groupId={groupId} />
        </ImageSide>
        <UserInteractiveSide>
          <NavigateButtons>
            <Button disabled={!prev} onClick={toPrev}>
              ＜ 前へ
            </Button>
            <Button disabled={!next} onClick={toNext}>
              次へ ＞
            </Button>
          </NavigateButtons>
          <DocumentEditCard
            key={documentId}
            document={results[currentIndex]}
            result={results[currentIndex]}
            onClose={close}
          />
        </UserInteractiveSide>
      </Container>
    </Modal>
  );
};

export default DocumentEditModal;
