import { Descriptions } from 'antd';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { adminApi } from '../../api/apiClient';
import PageLayout from '../../components/Container/PageLayout';

const MyPage = () => {
  const { email, name } = usePrepareMyPage();
  return (
    <PageLayout heading="マイページ">
      <UserInfo bordered colon={false} column={1}>
        <Descriptions.Item label="名前">{name}</Descriptions.Item>
        <Descriptions.Item label="メールアドレス">{email}</Descriptions.Item>
      </UserInfo>
    </PageLayout>
  );
};

function usePrepareMyPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const fetchData = useCallback(async () => {
    const { data } = await adminApi.adminUserControllerGetMe();
    setName(data.name);
    setEmail(data.email);
  }, []);

  fetchData();
  return { name, email };
}

export default MyPage;

const UserInfo = styled(Descriptions)`
  max-width: 720px;
`;
