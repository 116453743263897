import { FileExcelOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useCallback, useState } from 'react';

import { Paths } from '../../types/Paths';
import convertCSVColumns from '../../utils/convertCSVColumns';
import createCSVData from '../../utils/createCSVData';
import { downloadData } from '../../utils/downloadData';
import { notifyError } from '../../utils/notification';

type Props<T> = {
  disabled?: boolean;
  getRows: () => Promise<T[]>;
  columns: Record<string, Paths<T>>;
  fileName: string;
};
export default function CsvDownloadButton<T>(props: Props<T>) {
  const { columns, disabled, fileName, getRows } = props;
  const [loading, setLoading] = useState(false);

  const downloadCsv = useCallback(async () => {
    setLoading(true);
    const rows = await getRows();
    if (rows.length === 0) {
      notifyError('データがありません。');
      return;
    }
    const convertedData = convertCSVColumns(rows, columns);
    const csv = createCSVData(convertedData);
    downloadData(fileName, csv);
    setLoading(false);
  }, [columns, fileName, getRows]);

  return (
    <Button disabled={disabled} icon={<FileExcelOutlined />} loading={loading} type="primary" onClick={downloadCsv}>
      CSV出力
    </Button>
  );
}
