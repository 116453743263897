/* tslint:disable */
/* eslint-disable */
/**
 * OCRSOL API
 * OCRSOL API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AdminUser
 */
export interface AdminUser {
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminUser
   */
  password_change_required: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface AuthLoginRequest
 */
export interface AuthLoginRequest {
  /**
   *
   * @type {string}
   * @memberof AuthLoginRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AuthLoginRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof AuthLoginRequest
   */
  token: string;
}
/**
 *
 * @export
 * @interface AuthLoginResponse
 */
export interface AuthLoginResponse {
  /**
   *
   * @type {string}
   * @memberof AuthLoginResponse
   */
  access_token: string;
}
/**
 *
 * @export
 * @interface Company
 */
export interface Company {
  /**
   *
   * @type {string}
   * @memberof Company
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  company_name: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  company_rpa_id?: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  inactive_from?: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  inactive_process_at?: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  representative_user_id: string;
}
/**
 *
 * @export
 * @interface CompanyActivationRequest
 */
export interface CompanyActivationRequest {
  /**
   *
   * @type {string}
   * @memberof CompanyActivationRequest
   */
  plan_id: string;
}
/**
 *
 * @export
 * @interface CompanyCreateRequest
 */
export interface CompanyCreateRequest {
  /**
   *
   * @type {string}
   * @memberof CompanyCreateRequest
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof CompanyCreateRequest
   */
  company_name: string;
  /**
   *
   * @type {string}
   * @memberof CompanyCreateRequest
   */
  company_rpa_id: string;
  /**
   *
   * @type {string}
   * @memberof CompanyCreateRequest
   */
  plan_id: string;
  /**
   *
   * @type {string}
   * @memberof CompanyCreateRequest
   */
  representative_user_email: string;
  /**
   *
   * @type {string}
   * @memberof CompanyCreateRequest
   */
  representative_user_name: string;
}
/**
 *
 * @export
 * @interface CompanyCreateResponse
 */
export interface CompanyCreateResponse {
  /**
   *
   * @type {Company}
   * @memberof CompanyCreateResponse
   */
  company: Company;
  /**
   *
   * @type {CompanyPlan}
   * @memberof CompanyCreateResponse
   */
  plan: CompanyPlan;
  /**
   *
   * @type {string}
   * @memberof CompanyCreateResponse
   */
  representative_user_email: string;
  /**
   *
   * @type {string}
   * @memberof CompanyCreateResponse
   */
  representative_user_name: string;
}
/**
 *
 * @export
 * @interface CompanyInactivateRequest
 */
export interface CompanyInactivateRequest {
  /**
   *
   * @type {number}
   * @memberof CompanyInactivateRequest
   */
  inactive_from_month_index: number;
  /**
   *
   * @type {number}
   * @memberof CompanyInactivateRequest
   */
  inactive_from_year: number;
}
/**
 *
 * @export
 * @interface CompanyPlan
 */
export interface CompanyPlan {
  /**
   *
   * @type {string}
   * @memberof CompanyPlan
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof CompanyPlan
   */
  plan_id: string;
  /**
   *
   * @type {string}
   * @memberof CompanyPlan
   */
  since: string;
}
/**
 *
 * @export
 * @interface CompanyUpdatePlanRequest
 */
export interface CompanyUpdatePlanRequest {
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePlanRequest
   */
  plan_id: string;
}
/**
 *
 * @export
 * @interface CompanyUpdateRequest
 */
export interface CompanyUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof CompanyUpdateRequest
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdateRequest
   */
  company_name: string;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdateRequest
   */
  company_rpa_id: string;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdateRequest
   */
  representative_user_id: string;
}
/**
 *
 * @export
 * @interface CompanyUpdateResponse
 */
export interface CompanyUpdateResponse {
  /**
   *
   * @type {Company}
   * @memberof CompanyUpdateResponse
   */
  company: Company;
}
/**
 *
 * @export
 * @interface CompanyUsage
 */
export interface CompanyUsage {
  /**
   *
   * @type {number}
   * @memberof CompanyUsage
   */
  bill: number;
  /**
   *
   * @type {number}
   * @memberof CompanyUsage
   */
  bill_with_tax: number;
  /**
   *
   * @type {string}
   * @memberof CompanyUsage
   */
  company_id: string;
  /**
   *
   * @type {number}
   * @memberof CompanyUsage
   */
  free_usage: number;
  /**
   *
   * @type {string}
   * @memberof CompanyUsage
   */
  month: string;
  /**
   *
   * @type {number}
   * @memberof CompanyUsage
   */
  over_billing?: number;
  /**
   *
   * @type {number}
   * @memberof CompanyUsage
   */
  usage: number;
}
/**
 *
 * @export
 * @interface CompanyUsageWithPlan
 */
export interface CompanyUsageWithPlan {
  /**
   *
   * @type {number}
   * @memberof CompanyUsageWithPlan
   */
  base_fee: number | null;
  /**
   *
   * @type {number}
   * @memberof CompanyUsageWithPlan
   */
  bill: number;
  /**
   *
   * @type {number}
   * @memberof CompanyUsageWithPlan
   */
  bill_with_tax: number;
  /**
   *
   * @type {string}
   * @memberof CompanyUsageWithPlan
   */
  company_id: string;
  /**
   *
   * @type {number}
   * @memberof CompanyUsageWithPlan
   */
  free_usage: number;
  /**
   *
   * @type {string}
   * @memberof CompanyUsageWithPlan
   */
  month: string;
  /**
   *
   * @type {number}
   * @memberof CompanyUsageWithPlan
   */
  over_billing?: number;
  /**
   *
   * @type {string}
   * @memberof CompanyUsageWithPlan
   */
  plan_id: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyUsageWithPlan
   */
  plan_name: string | null;
  /**
   *
   * @type {number}
   * @memberof CompanyUsageWithPlan
   */
  usage: number;
}
/**
 *
 * @export
 * @interface CreateAdminUserRequest
 */
export interface CreateAdminUserRequest {
  /**
   *
   * @type {string}
   * @memberof CreateAdminUserRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateAdminUserRequest
   */
  name: string;
}
/**
 *
 * @export
 * @interface CreateAdminUserResponse
 */
export interface CreateAdminUserResponse {
  /**
   *
   * @type {string}
   * @memberof CreateAdminUserResponse
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateAdminUserResponse
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateAdminUserResponse
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof CreateAdminUserResponse
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  name: string;
  /**
   *
   * @type {UserRole}
   * @memberof CreateUserRequest
   */
  role: UserRole;
}
/**
 *
 * @export
 * @interface CreateUserResponse
 */
export interface CreateUserResponse {
  /**
   *
   * @type {string}
   * @memberof CreateUserResponse
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserResponse
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserResponse
   */
  password: string;
}
/**
 *
 * @export
 * @interface Document
 */
export interface Document {
  /**
   *
   * @type {string}
   * @memberof Document
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  document_id: string;
  /**
   *
   * @type {boolean}
   * @memberof Document
   */
  edited: boolean;
  /**
   *
   * @type {DocumentOcrErrorEntity}
   * @memberof Document
   */
  error?: DocumentOcrErrorEntity;
  /**
   *
   * @type {boolean}
   * @memberof Document
   */
  fully_read: boolean;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  group_id: string;
  /**
   *
   * @type {DocumentMedicalBillEntity | DocumentFurusatoTaxEntity | DocumentBankBookEntity | DocumentCardStatementEntity | DocumentGeneralTableEntity | DocumentReceiptEntity | DocumentPaymentRecordEntity | DocumentDualHorizontalReceiptEntity | DocumentDualVerticalReceiptEntity | DocumentWithholdingSlipEntity | DocumentOcrErrorEntity}
   * @memberof Document
   */
  latest_revision:
    | DocumentMedicalBillEntity
    | DocumentFurusatoTaxEntity
    | DocumentBankBookEntity
    | DocumentCardStatementEntity
    | DocumentGeneralTableEntity
    | DocumentReceiptEntity
    | DocumentPaymentRecordEntity
    | DocumentDualHorizontalReceiptEntity
    | DocumentDualVerticalReceiptEntity
    | DocumentWithholdingSlipEntity
    | DocumentOcrErrorEntity;
  /**
   *
   * @type {DocumentMedicalBillEntity | DocumentFurusatoTaxEntity | DocumentBankBookEntity | DocumentCardStatementEntity | DocumentGeneralTableEntity | DocumentReceiptEntity | DocumentPaymentRecordEntity | DocumentDualHorizontalReceiptEntity | DocumentDualVerticalReceiptEntity | DocumentWithholdingSlipEntity | DocumentOcrErrorEntity}
   * @memberof Document
   */
  ocr_result:
    | DocumentMedicalBillEntity
    | DocumentFurusatoTaxEntity
    | DocumentBankBookEntity
    | DocumentCardStatementEntity
    | DocumentGeneralTableEntity
    | DocumentReceiptEntity
    | DocumentPaymentRecordEntity
    | DocumentDualHorizontalReceiptEntity
    | DocumentDualVerticalReceiptEntity
    | DocumentWithholdingSlipEntity
    | DocumentOcrErrorEntity;
  /**
   *
   * @type {DocumentType}
   * @memberof Document
   */
  ocr_type: DocumentType;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  page?: number;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  s3_path?: string;
  /**
   *
   * @type {boolean}
   * @memberof Document
   */
  seen?: boolean;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  total_page?: number;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  user_file_path: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface DocumentBankBookEntity
 */
export interface DocumentBankBookEntity {
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentBankBookEntity
   */
  balance_calculation_result?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentBankBookEntity
   */
  columnIds?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentBankBookEntity
   */
  column_names?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentBankBookEntity
   */
  rowIds?: Array<string>;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof DocumentBankBookEntity
   */
  rows?: Array<Array<string>>;
  /**
   *
   * @type {string}
   * @memberof DocumentBankBookEntity
   */
  type: DocumentBankBookEntityTypeEnum;
}

export const DocumentBankBookEntityTypeEnum = {
  Bankbook: 'bankbook',
} as const;

export type DocumentBankBookEntityTypeEnum =
  typeof DocumentBankBookEntityTypeEnum[keyof typeof DocumentBankBookEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentCardStatementEntity
 */
export interface DocumentCardStatementEntity {
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentCardStatementEntity
   */
  columnIds?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentCardStatementEntity
   */
  column_names?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentCardStatementEntity
   */
  rowIds?: Array<string>;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof DocumentCardStatementEntity
   */
  rows?: Array<Array<string>>;
  /**
   *
   * @type {string}
   * @memberof DocumentCardStatementEntity
   */
  type: DocumentCardStatementEntityTypeEnum;
}

export const DocumentCardStatementEntityTypeEnum = {
  CardStatement: 'card_statement',
} as const;

export type DocumentCardStatementEntityTypeEnum =
  typeof DocumentCardStatementEntityTypeEnum[keyof typeof DocumentCardStatementEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentDualHorizontalReceiptEntity
 */
export interface DocumentDualHorizontalReceiptEntity {
  /**
   *
   * @type {Array<DocumentReceiptEntity>}
   * @memberof DocumentDualHorizontalReceiptEntity
   */
  receipts: Array<DocumentReceiptEntity>;
  /**
   *
   * @type {string}
   * @memberof DocumentDualHorizontalReceiptEntity
   */
  type: DocumentDualHorizontalReceiptEntityTypeEnum;
}

export const DocumentDualHorizontalReceiptEntityTypeEnum = {
  DualHorizontalReceipt: 'dual_horizontal_receipt',
} as const;

export type DocumentDualHorizontalReceiptEntityTypeEnum =
  typeof DocumentDualHorizontalReceiptEntityTypeEnum[keyof typeof DocumentDualHorizontalReceiptEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentDualVerticalReceiptEntity
 */
export interface DocumentDualVerticalReceiptEntity {
  /**
   *
   * @type {Array<DocumentReceiptEntity>}
   * @memberof DocumentDualVerticalReceiptEntity
   */
  receipts: Array<DocumentReceiptEntity>;
  /**
   *
   * @type {string}
   * @memberof DocumentDualVerticalReceiptEntity
   */
  type: DocumentDualVerticalReceiptEntityTypeEnum;
}

export const DocumentDualVerticalReceiptEntityTypeEnum = {
  DualVerticalReceipt: 'dual_vertical_receipt',
} as const;

export type DocumentDualVerticalReceiptEntityTypeEnum =
  typeof DocumentDualVerticalReceiptEntityTypeEnum[keyof typeof DocumentDualVerticalReceiptEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentFurusatoTaxEntity
 */
export interface DocumentFurusatoTaxEntity {
  /**
   *
   * @type {string}
   * @memberof DocumentFurusatoTaxEntity
   */
  address?: string;
  /**
   *
   * @type {number}
   * @memberof DocumentFurusatoTaxEntity
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof DocumentFurusatoTaxEntity
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentFurusatoTaxEntity
   */
  payment_day?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentFurusatoTaxEntity
   */
  prefecture?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentFurusatoTaxEntity
   */
  type: DocumentFurusatoTaxEntityTypeEnum;
}

export const DocumentFurusatoTaxEntityTypeEnum = {
  FurusatoTax: 'furusato_tax',
} as const;

export type DocumentFurusatoTaxEntityTypeEnum =
  typeof DocumentFurusatoTaxEntityTypeEnum[keyof typeof DocumentFurusatoTaxEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentGeneralTableEntity
 */
export interface DocumentGeneralTableEntity {
  /**
   *
   * @type {Array<Array<Array<string>>>}
   * @memberof DocumentGeneralTableEntity
   */
  tables: Array<Array<Array<string>>>;
  /**
   *
   * @type {string}
   * @memberof DocumentGeneralTableEntity
   */
  type: DocumentGeneralTableEntityTypeEnum;
}

export const DocumentGeneralTableEntityTypeEnum = {
  GeneralTable: 'general_table',
} as const;

export type DocumentGeneralTableEntityTypeEnum =
  typeof DocumentGeneralTableEntityTypeEnum[keyof typeof DocumentGeneralTableEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentMedicalBillEntity
 */
export interface DocumentMedicalBillEntity {
  /**
   *
   * @type {string}
   * @memberof DocumentMedicalBillEntity
   */
  address?: string;
  /**
   *
   * @type {number}
   * @memberof DocumentMedicalBillEntity
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof DocumentMedicalBillEntity
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentMedicalBillEntity
   */
  hospital_name?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentMedicalBillEntity
   */
  patient_name?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentMedicalBillEntity
   */
  type: DocumentMedicalBillEntityTypeEnum;
}

export const DocumentMedicalBillEntityTypeEnum = {
  MedicalBill: 'medical_bill',
} as const;

export type DocumentMedicalBillEntityTypeEnum =
  typeof DocumentMedicalBillEntityTypeEnum[keyof typeof DocumentMedicalBillEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentOcrErrorEntity
 */
export interface DocumentOcrErrorEntity {
  /**
   *
   * @type {string}
   * @memberof DocumentOcrErrorEntity
   */
  error_message?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentOcrErrorEntity
   */
  type: DocumentOcrErrorEntityTypeEnum;
}

export const DocumentOcrErrorEntityTypeEnum = {
  OcrError: 'ocr_error',
} as const;

export type DocumentOcrErrorEntityTypeEnum =
  typeof DocumentOcrErrorEntityTypeEnum[keyof typeof DocumentOcrErrorEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentPaymentRecordEntity
 */
export interface DocumentPaymentRecordEntity {
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  earner_address?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  earner_individual_or_corporate_number?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  earner_name?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  payer_address?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  payer_individual_or_corporate_number?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  payer_name?: string;
  /**
   *
   * @type {Array<Payment>}
   * @memberof DocumentPaymentRecordEntity
   */
  payments?: Array<Payment>;
  /**
   *
   * @type {number}
   * @memberof DocumentPaymentRecordEntity
   */
  total_amount?: number;
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  type: DocumentPaymentRecordEntityTypeEnum;
}

export const DocumentPaymentRecordEntityTypeEnum = {
  PaymentRecord: 'payment_record',
} as const;

export type DocumentPaymentRecordEntityTypeEnum =
  typeof DocumentPaymentRecordEntityTypeEnum[keyof typeof DocumentPaymentRecordEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentReceiptEntity
 */
export interface DocumentReceiptEntity {
  /**
   *
   * @type {string}
   * @memberof DocumentReceiptEntity
   */
  business_number?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentReceiptEntity
   */
  payee?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof DocumentReceiptEntity
   */
  payment_date?: string;
  /**
   *
   * @type {Array<ReceiptDetail>}
   * @memberof DocumentReceiptEntity
   */
  receipt_details?: Array<ReceiptDetail>;
  /**
   *
   * @type {string}
   * @memberof DocumentReceiptEntity
   */
  type: DocumentReceiptEntityTypeEnum;
}

export const DocumentReceiptEntityTypeEnum = {
  Receipt: 'receipt',
} as const;

export type DocumentReceiptEntityTypeEnum =
  typeof DocumentReceiptEntityTypeEnum[keyof typeof DocumentReceiptEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentSearchResponse
 */
export interface DocumentSearchResponse {
  /**
   *
   * @type {Array<SearchResult>}
   * @memberof DocumentSearchResponse
   */
  documents: Array<SearchResult>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DocumentType = {
  MedicalBill: 'medical_bill',
  FurusatoTax: 'furusato_tax',
  Bankbook: 'bankbook',
  CardStatement: 'card_statement',
  GeneralTable: 'general_table',
  Receipt: 'receipt',
  PaymentRecord: 'payment_record',
  DualHorizontalReceipt: 'dual_horizontal_receipt',
  DualVerticalReceipt: 'dual_vertical_receipt',
  WithholdingSlip: 'withholding_slip',
  OcrError: 'ocr_error',
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];

/**
 *
 * @export
 * @interface DocumentWithholdingSlipEntity
 */
export interface DocumentWithholdingSlipEntity {
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  care_insurance?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  date_of_birth?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  earner_address?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  earner_name?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  earner_number?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  earthquake_insurance_deduction?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  employer_address?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  employer_name?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  employer_number?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  employer_phone?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  house_loan_deduction?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  income_type?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  life_insurance_deduction?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  new_life_insurance?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  new_personal_pension_insurance?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  old_life_insurance?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  old_personal_pension_insurance?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  other?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  payment_amount?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  social_insurance?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  spouse_deduction?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  spouse_income?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  type: DocumentWithholdingSlipEntityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  withholding_amount?: string;
}

export const DocumentWithholdingSlipEntityTypeEnum = {
  WithholdingSlip: 'withholding_slip',
} as const;

export type DocumentWithholdingSlipEntityTypeEnum =
  typeof DocumentWithholdingSlipEntityTypeEnum[keyof typeof DocumentWithholdingSlipEntityTypeEnum];

/**
 *
 * @export
 * @interface EditAdminUserRequest
 */
export interface EditAdminUserRequest {
  /**
   *
   * @type {string}
   * @memberof EditAdminUserRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof EditAdminUserRequest
   */
  name: string;
}
/**
 *
 * @export
 * @interface Fee
 */
export interface Fee {
  /**
   *
   * @type {number}
   * @memberof Fee
   */
  base: number;
  /**
   *
   * @type {number}
   * @memberof Fee
   */
  minimum_page: number;
  /**
   *
   * @type {number}
   * @memberof Fee
   */
  rate: number;
}
/**
 *
 * @export
 * @interface GetCompanyPlansResponse
 */
export interface GetCompanyPlansResponse {
  /**
   *
   * @type {Array<CompanyPlan>}
   * @memberof GetCompanyPlansResponse
   */
  companyPlans: Array<CompanyPlan>;
}
/**
 *
 * @export
 * @interface GetDividedImagePresignedUrlResponse
 */
export interface GetDividedImagePresignedUrlResponse {
  /**
   *
   * @type {string}
   * @memberof GetDividedImagePresignedUrlResponse
   */
  image_url: string;
}
/**
 *
 * @export
 * @interface GetDocumentGroupResponse
 */
export interface GetDocumentGroupResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof GetDocumentGroupResponse
   */
  completed_source_bucket_keys: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof GetDocumentGroupResponse
   */
  errors_source_bucket_keys: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GetDocumentGroupResponse
   */
  files_count: number;
  /**
   *
   * @type {string}
   * @memberof GetDocumentGroupResponse
   */
  group_id: string;
  /**
   *
   * @type {DocumentType}
   * @memberof GetDocumentGroupResponse
   */
  ocr_type: DocumentType;
  /**
   *
   * @type {string}
   * @memberof GetDocumentGroupResponse
   */
  updated_at: string;
  /**
   *
   * @type {string}
   * @memberof GetDocumentGroupResponse
   */
  uploaded_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetDocumentGroupResponse
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface GetStatisticsPerFormResponse
 */
export interface GetStatisticsPerFormResponse {
  /**
   *
   * @type {Array<UsagePerForm>}
   * @memberof GetStatisticsPerFormResponse
   */
  statistics: Array<UsagePerForm>;
}
/**
 *
 * @export
 * @interface GetStatisticsPerUserResponse
 */
export interface GetStatisticsPerUserResponse {
  /**
   *
   * @type {Array<UsagePerUser>}
   * @memberof GetStatisticsPerUserResponse
   */
  statistics: Array<UsagePerUser>;
}
/**
 *
 * @export
 * @interface PasswordChangeMailRequest
 */
export interface PasswordChangeMailRequest {
  /**
   *
   * @type {string}
   * @memberof PasswordChangeMailRequest
   */
  email: string;
}
/**
 *
 * @export
 * @interface PasswordChangeRequest
 */
export interface PasswordChangeRequest {
  /**
   *
   * @type {string}
   * @memberof PasswordChangeRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface PasswordChangeSignatureRequest
 */
export interface PasswordChangeSignatureRequest {
  /**
   *
   * @type {number}
   * @memberof PasswordChangeSignatureRequest
   */
  expire: number;
  /**
   *
   * @type {string}
   * @memberof PasswordChangeSignatureRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChangeSignatureRequest
   */
  signature: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChangeSignatureRequest
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChangeSignatureRequest
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface Payment
 */
export interface Payment {
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  classification?: string;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  detail?: string;
  /**
   *
   * @type {number}
   * @memberof Payment
   */
  payment_amount?: number;
  /**
   *
   * @type {number}
   * @memberof Payment
   */
  withholding_tax_amount?: number;
}
/**
 *
 * @export
 * @interface Plan
 */
export interface Plan {
  /**
   *
   * @type {Array<Fee>}
   * @memberof Plan
   */
  fee: Array<Fee>;
  /**
   *
   * @type {string}
   * @memberof Plan
   */
  plan_id: string;
  /**
   *
   * @type {string}
   * @memberof Plan
   */
  plan_name: string;
  /**
   *
   * @type {string}
   * @memberof Plan
   */
  plan_start_time: string;
  /**
   *
   * @type {number}
   * @memberof Plan
   */
  tax_percent: number;
}
/**
 *
 * @export
 * @interface ReceiptDetail
 */
export interface ReceiptDetail {
  /**
   *
   * @type {string}
   * @memberof ReceiptDetail
   */
  account?: string;
  /**
   *
   * @type {number}
   * @memberof ReceiptDetail
   */
  amount_with_tax?: number;
  /**
   *
   * @type {number}
   * @memberof ReceiptDetail
   */
  amount_without_tax?: number;
  /**
   *
   * @type {number}
   * @memberof ReceiptDetail
   */
  tax_amount?: number;
  /**
   *
   * @type {number}
   * @memberof ReceiptDetail
   */
  tax_percent?: number;
}
/**
 *
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
  /**
   *
   * @type {Array<string>}
   * @memberof SearchResult
   */
  file_names: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SearchResult
   */
  group_id: string;
  /**
   *
   * @type {DocumentType}
   * @memberof SearchResult
   */
  ocr_type: DocumentType;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchResult
   */
  seen_documents: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SearchResult
   */
  total_page: number;
  /**
   *
   * @type {string}
   * @memberof SearchResult
   */
  uploaded_at: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchResult
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface UsagePerForm
 */
export interface UsagePerForm {
  /**
   *
   * @type {string}
   * @memberof UsagePerForm
   */
  month: string;
  /**
   *
   * @type {DocumentType}
   * @memberof UsagePerForm
   */
  ocr_type: DocumentType;
  /**
   *
   * @type {number}
   * @memberof UsagePerForm
   */
  usage: number;
}
/**
 *
 * @export
 * @interface UsagePerUser
 */
export interface UsagePerUser {
  /**
   *
   * @type {string}
   * @memberof UsagePerUser
   */
  month: string;
  /**
   *
   * @type {number}
   * @memberof UsagePerUser
   */
  usage: number;
  /**
   *
   * @type {string}
   * @memberof UsagePerUser
   */
  user_name: string;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  company_name: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  password_change_required: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  role: UserRoleEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  user_id: string;
}

export const UserRoleEnum = {
  Admin: 'admin',
  Member: 'member',
} as const;

export type UserRoleEnum = typeof UserRoleEnum[keyof typeof UserRoleEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const UserRole = {
  Admin: 'admin',
  Member: 'member',
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];

/**
 *
 * @export
 * @interface UserUpdateUserRoleRequest
 */
export interface UserUpdateUserRoleRequest {
  /**
   *
   * @type {string}
   * @memberof UserUpdateUserRoleRequest
   */
  role: UserUpdateUserRoleRequestRoleEnum;
}

export const UserUpdateUserRoleRequestRoleEnum = {
  Admin: 'admin',
  Member: 'member',
} as const;

export type UserUpdateUserRoleRequestRoleEnum =
  typeof UserUpdateUserRoleRequestRoleEnum[keyof typeof UserUpdateUserRoleRequestRoleEnum];

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateAdminUserRequest} createAdminUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserControllerCreateAdminUser: async (
      createAdminUserRequest: CreateAdminUserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createAdminUserRequest' is not null or undefined
      assertParamExists('adminUserControllerCreateAdminUser', 'createAdminUserRequest', createAdminUserRequest);
      const localVarPath = `/admin/users/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAdminUserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserControllerDeleteAdminUser: async (
      user: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'user' is not null or undefined
      assertParamExists('adminUserControllerDeleteAdminUser', 'user', user);
      const localVarPath = `/admin/users/{user}`.replace(`{${'user'}}`, encodeURIComponent(String(user)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} user
     * @param {EditAdminUserRequest} editAdminUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserControllerEditAdminUser: async (
      user: string,
      editAdminUserRequest: EditAdminUserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'user' is not null or undefined
      assertParamExists('adminUserControllerEditAdminUser', 'user', user);
      // verify required parameter 'editAdminUserRequest' is not null or undefined
      assertParamExists('adminUserControllerEditAdminUser', 'editAdminUserRequest', editAdminUserRequest);
      const localVarPath = `/admin/users/{user}`.replace(`{${'user'}}`, encodeURIComponent(String(user)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(editAdminUserRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserControllerGetAdminUser: async (user: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'user' is not null or undefined
      assertParamExists('adminUserControllerGetAdminUser', 'user', user);
      const localVarPath = `/admin/users/{user}`.replace(`{${'user'}}`, encodeURIComponent(String(user)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserControllerGetAllAdminUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/users/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserControllerGetMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/users/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateAdminUserRequest} createAdminUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserControllerCreateAdminUser(
      createAdminUserRequest: CreateAdminUserRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAdminUserResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerCreateAdminUser(
        createAdminUserRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserControllerDeleteAdminUser(
      user: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerDeleteAdminUser(user, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} user
     * @param {EditAdminUserRequest} editAdminUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserControllerEditAdminUser(
      user: string,
      editAdminUserRequest: EditAdminUserRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerEditAdminUser(
        user,
        editAdminUserRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserControllerGetAdminUser(
      user: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerGetAdminUser(user, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserControllerGetAllAdminUser(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminUser>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerGetAllAdminUser(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserControllerGetMe(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerGetMe(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AdminApiFp(configuration);
  return {
    /**
     *
     * @param {CreateAdminUserRequest} createAdminUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserControllerCreateAdminUser(
      createAdminUserRequest: CreateAdminUserRequest,
      options?: any
    ): AxiosPromise<CreateAdminUserResponse> {
      return localVarFp
        .adminUserControllerCreateAdminUser(createAdminUserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserControllerDeleteAdminUser(user: string, options?: any): AxiosPromise<AdminUser> {
      return localVarFp.adminUserControllerDeleteAdminUser(user, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} user
     * @param {EditAdminUserRequest} editAdminUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserControllerEditAdminUser(
      user: string,
      editAdminUserRequest: EditAdminUserRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .adminUserControllerEditAdminUser(user, editAdminUserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserControllerGetAdminUser(user: string, options?: any): AxiosPromise<AdminUser> {
      return localVarFp.adminUserControllerGetAdminUser(user, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserControllerGetAllAdminUser(options?: any): AxiosPromise<Array<AdminUser>> {
      return localVarFp.adminUserControllerGetAllAdminUser(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserControllerGetMe(options?: any): AxiosPromise<AdminUser> {
      return localVarFp.adminUserControllerGetMe(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
  /**
   *
   * @param {CreateAdminUserRequest} createAdminUserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminUserControllerCreateAdminUser(
    createAdminUserRequest: CreateAdminUserRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminUserControllerCreateAdminUser(createAdminUserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminUserControllerDeleteAdminUser(user: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .adminUserControllerDeleteAdminUser(user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} user
   * @param {EditAdminUserRequest} editAdminUserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminUserControllerEditAdminUser(
    user: string,
    editAdminUserRequest: EditAdminUserRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .adminUserControllerEditAdminUser(user, editAdminUserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminUserControllerGetAdminUser(user: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .adminUserControllerGetAdminUser(user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminUserControllerGetAllAdminUser(options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .adminUserControllerGetAllAdminUser(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminUserControllerGetMe(options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .adminUserControllerGetMe(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {PasswordChangeRequest} passwordChangeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerChangePassword: async (
      passwordChangeRequest: PasswordChangeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordChangeRequest' is not null or undefined
      assertParamExists('authControllerChangePassword', 'passwordChangeRequest', passwordChangeRequest);
      const localVarPath = `/auth/password/change`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PasswordChangeSignatureRequest} passwordChangeSignatureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerChangePasswordWithSignature: async (
      passwordChangeSignatureRequest: PasswordChangeSignatureRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordChangeSignatureRequest' is not null or undefined
      assertParamExists(
        'authControllerChangePasswordWithSignature',
        'passwordChangeSignatureRequest',
        passwordChangeSignatureRequest
      );
      const localVarPath = `/auth/password/change/signature`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        passwordChangeSignatureRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AuthLoginRequest} authLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerLogin: async (
      authLoginRequest: AuthLoginRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authLoginRequest' is not null or undefined
      assertParamExists('authControllerLogin', 'authLoginRequest', authLoginRequest);
      const localVarPath = `/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(authLoginRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PasswordChangeMailRequest} passwordChangeMailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendPasswordChangeMail: async (
      passwordChangeMailRequest: PasswordChangeMailRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordChangeMailRequest' is not null or undefined
      assertParamExists('authControllerSendPasswordChangeMail', 'passwordChangeMailRequest', passwordChangeMailRequest);
      const localVarPath = `/auth/password/send`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        passwordChangeMailRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {PasswordChangeRequest} passwordChangeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerChangePassword(
      passwordChangeRequest: PasswordChangeRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerChangePassword(
        passwordChangeRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PasswordChangeSignatureRequest} passwordChangeSignatureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerChangePasswordWithSignature(
      passwordChangeSignatureRequest: PasswordChangeSignatureRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerChangePasswordWithSignature(
        passwordChangeSignatureRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AuthLoginRequest} authLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerLogin(
      authLoginRequest: AuthLoginRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthLoginResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(authLoginRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PasswordChangeMailRequest} passwordChangeMailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerSendPasswordChangeMail(
      passwordChangeMailRequest: PasswordChangeMailRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSendPasswordChangeMail(
        passwordChangeMailRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @param {PasswordChangeRequest} passwordChangeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerChangePassword(passwordChangeRequest: PasswordChangeRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .authControllerChangePassword(passwordChangeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PasswordChangeSignatureRequest} passwordChangeSignatureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerChangePasswordWithSignature(
      passwordChangeSignatureRequest: PasswordChangeSignatureRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .authControllerChangePasswordWithSignature(passwordChangeSignatureRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AuthLoginRequest} authLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerLogin(authLoginRequest: AuthLoginRequest, options?: any): AxiosPromise<AuthLoginResponse> {
      return localVarFp.authControllerLogin(authLoginRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PasswordChangeMailRequest} passwordChangeMailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendPasswordChangeMail(
      passwordChangeMailRequest: PasswordChangeMailRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .authControllerSendPasswordChangeMail(passwordChangeMailRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @param {PasswordChangeRequest} passwordChangeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerChangePassword(passwordChangeRequest: PasswordChangeRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authControllerChangePassword(passwordChangeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PasswordChangeSignatureRequest} passwordChangeSignatureRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerChangePasswordWithSignature(
    passwordChangeSignatureRequest: PasswordChangeSignatureRequest,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .authControllerChangePasswordWithSignature(passwordChangeSignatureRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthLoginRequest} authLoginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerLogin(authLoginRequest: AuthLoginRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authControllerLogin(authLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PasswordChangeMailRequest} passwordChangeMailRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerSendPasswordChangeMail(
    passwordChangeMailRequest: PasswordChangeMailRequest,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .authControllerSendPasswordChangeMail(passwordChangeMailRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} companyId
     * @param {CompanyActivationRequest} companyActivationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerActivateCompany: async (
      companyId: string,
      companyActivationRequest: CompanyActivationRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyControllerActivateCompany', 'companyId', companyId);
      // verify required parameter 'companyActivationRequest' is not null or undefined
      assertParamExists('companyControllerActivateCompany', 'companyActivationRequest', companyActivationRequest);
      const localVarPath = `/companies/{companyId}/activate`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        companyActivationRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CompanyCreateRequest} companyCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerCreateCompany: async (
      companyCreateRequest: CompanyCreateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyCreateRequest' is not null or undefined
      assertParamExists('companyControllerCreateCompany', 'companyCreateRequest', companyCreateRequest);
      const localVarPath = `/companies/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(companyCreateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetActiveCompanyList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/active`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetAllCompanyList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<string>} companyIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompanies: async (
      companyIds: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyIds' is not null or undefined
      assertParamExists('companyControllerGetCompanies', 'companyIds', companyIds);
      const localVarPath = `/companies/batch`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (companyIds) {
        localVarQueryParameter['companyIds'] = companyIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompany: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyControllerGetCompany', 'companyId', companyId);
      const localVarPath = `/companies/{companyId}`.replace(`{${'companyId'}}`, encodeURIComponent(String(companyId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompanyLastPlans: async (
      companyIds: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyIds' is not null or undefined
      assertParamExists('companyControllerGetCompanyLastPlans', 'companyIds', companyIds);
      const localVarPath = `/companies/batch/plans`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (companyIds !== undefined) {
        localVarQueryParameter['companyIds'] = companyIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompanyPlan: async (
      companyId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyControllerGetCompanyPlan', 'companyId', companyId);
      const localVarPath = `/companies/{companyId}/plans`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompanyPlansAtMonth: async (
      month: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'month' is not null or undefined
      assertParamExists('companyControllerGetCompanyPlansAtMonth', 'month', month);
      const localVarPath = `/companies/plans/{month}`.replace(`{${'month'}}`, encodeURIComponent(String(month)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompanyUsers: async (
      companyId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyControllerGetCompanyUsers', 'companyId', companyId);
      const localVarPath = `/companies/{companyId}/users`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetInactiveCompanyList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/inactive`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetPlan: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyControllerGetPlan', 'companyId', companyId);
      const localVarPath = `/companies/{companyId}/plan`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetPlanAtLastMonth: async (
      companyId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyControllerGetPlanAtLastMonth', 'companyId', companyId);
      const localVarPath = `/companies/{companyId}/plan/lastmonth`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetPlanList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/plans`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {CompanyInactivateRequest} companyInactivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerInactivateCompany: async (
      companyId: string,
      companyInactivateRequest: CompanyInactivateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyControllerInactivateCompany', 'companyId', companyId);
      // verify required parameter 'companyInactivateRequest' is not null or undefined
      assertParamExists('companyControllerInactivateCompany', 'companyInactivateRequest', companyInactivateRequest);
      const localVarPath = `/companies/{companyId}/inactivate`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        companyInactivateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {CompanyUpdateRequest} companyUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerUpdateCompany: async (
      companyId: string,
      companyUpdateRequest: CompanyUpdateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyControllerUpdateCompany', 'companyId', companyId);
      // verify required parameter 'companyUpdateRequest' is not null or undefined
      assertParamExists('companyControllerUpdateCompany', 'companyUpdateRequest', companyUpdateRequest);
      const localVarPath = `/companies/{companyId}`.replace(`{${'companyId'}}`, encodeURIComponent(String(companyId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(companyUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {CompanyUpdatePlanRequest} companyUpdatePlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerUpdateCompanyPlan: async (
      companyId: string,
      companyUpdatePlanRequest: CompanyUpdatePlanRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyControllerUpdateCompanyPlan', 'companyId', companyId);
      // verify required parameter 'companyUpdatePlanRequest' is not null or undefined
      assertParamExists('companyControllerUpdateCompanyPlan', 'companyUpdatePlanRequest', companyUpdatePlanRequest);
      const localVarPath = `/companies/{companyId}/plans`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        companyUpdatePlanRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} companyId
     * @param {CompanyActivationRequest} companyActivationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerActivateCompany(
      companyId: string,
      companyActivationRequest: CompanyActivationRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerActivateCompany(
        companyId,
        companyActivationRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CompanyCreateRequest} companyCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerCreateCompany(
      companyCreateRequest: CompanyCreateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCreateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerCreateCompany(
        companyCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetActiveCompanyList(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetActiveCompanyList(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetAllCompanyList(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetAllCompanyList(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Array<string>} companyIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetCompanies(
      companyIds: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetCompanies(companyIds, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetCompany(
      companyId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetCompany(companyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetCompanyLastPlans(
      companyIds: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyPlan>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetCompanyLastPlans(
        companyIds,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetCompanyPlan(
      companyId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyPlansResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetCompanyPlan(companyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetCompanyPlansAtMonth(
      month: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyPlan>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetCompanyPlansAtMonth(month, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetCompanyUsers(
      companyId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetCompanyUsers(companyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetInactiveCompanyList(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetInactiveCompanyList(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetPlan(
      companyId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetPlan(companyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetPlanAtLastMonth(
      companyId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetPlanAtLastMonth(companyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetPlanList(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Plan>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetPlanList(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyId
     * @param {CompanyInactivateRequest} companyInactivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerInactivateCompany(
      companyId: string,
      companyInactivateRequest: CompanyInactivateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerInactivateCompany(
        companyId,
        companyInactivateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyId
     * @param {CompanyUpdateRequest} companyUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerUpdateCompany(
      companyId: string,
      companyUpdateRequest: CompanyUpdateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyUpdateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerUpdateCompany(
        companyId,
        companyUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyId
     * @param {CompanyUpdatePlanRequest} companyUpdatePlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerUpdateCompanyPlan(
      companyId: string,
      companyUpdatePlanRequest: CompanyUpdatePlanRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerUpdateCompanyPlan(
        companyId,
        companyUpdatePlanRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CompaniesApiFp(configuration);
  return {
    /**
     *
     * @param {string} companyId
     * @param {CompanyActivationRequest} companyActivationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerActivateCompany(
      companyId: string,
      companyActivationRequest: CompanyActivationRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .companyControllerActivateCompany(companyId, companyActivationRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CompanyCreateRequest} companyCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerCreateCompany(
      companyCreateRequest: CompanyCreateRequest,
      options?: any
    ): AxiosPromise<CompanyCreateResponse> {
      return localVarFp
        .companyControllerCreateCompany(companyCreateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetActiveCompanyList(options?: any): AxiosPromise<Array<Company>> {
      return localVarFp.companyControllerGetActiveCompanyList(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetAllCompanyList(options?: any): AxiosPromise<Array<Company>> {
      return localVarFp.companyControllerGetAllCompanyList(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<string>} companyIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompanies(companyIds: Array<string>, options?: any): AxiosPromise<Array<Company>> {
      return localVarFp.companyControllerGetCompanies(companyIds, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompany(companyId: string, options?: any): AxiosPromise<Company> {
      return localVarFp.companyControllerGetCompany(companyId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompanyLastPlans(companyIds: string, options?: any): AxiosPromise<Array<CompanyPlan>> {
      return localVarFp
        .companyControllerGetCompanyLastPlans(companyIds, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompanyPlan(companyId: string, options?: any): AxiosPromise<GetCompanyPlansResponse> {
      return localVarFp.companyControllerGetCompanyPlan(companyId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompanyPlansAtMonth(month: string, options?: any): AxiosPromise<Array<CompanyPlan>> {
      return localVarFp
        .companyControllerGetCompanyPlansAtMonth(month, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompanyUsers(companyId: string, options?: any): AxiosPromise<Array<User>> {
      return localVarFp
        .companyControllerGetCompanyUsers(companyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetInactiveCompanyList(options?: any): AxiosPromise<Array<Company>> {
      return localVarFp.companyControllerGetInactiveCompanyList(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetPlan(companyId: string, options?: any): AxiosPromise<Plan> {
      return localVarFp.companyControllerGetPlan(companyId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetPlanAtLastMonth(companyId: string, options?: any): AxiosPromise<Plan> {
      return localVarFp
        .companyControllerGetPlanAtLastMonth(companyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetPlanList(options?: any): AxiosPromise<Array<Plan>> {
      return localVarFp.companyControllerGetPlanList(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {CompanyInactivateRequest} companyInactivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerInactivateCompany(
      companyId: string,
      companyInactivateRequest: CompanyInactivateRequest,
      options?: any
    ): AxiosPromise<Company> {
      return localVarFp
        .companyControllerInactivateCompany(companyId, companyInactivateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {CompanyUpdateRequest} companyUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerUpdateCompany(
      companyId: string,
      companyUpdateRequest: CompanyUpdateRequest,
      options?: any
    ): AxiosPromise<CompanyUpdateResponse> {
      return localVarFp
        .companyControllerUpdateCompany(companyId, companyUpdateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {CompanyUpdatePlanRequest} companyUpdatePlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerUpdateCompanyPlan(
      companyId: string,
      companyUpdatePlanRequest: CompanyUpdatePlanRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .companyControllerUpdateCompanyPlan(companyId, companyUpdatePlanRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
  /**
   *
   * @param {string} companyId
   * @param {CompanyActivationRequest} companyActivationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerActivateCompany(
    companyId: string,
    companyActivationRequest: CompanyActivationRequest,
    options?: AxiosRequestConfig
  ) {
    return CompaniesApiFp(this.configuration)
      .companyControllerActivateCompany(companyId, companyActivationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CompanyCreateRequest} companyCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerCreateCompany(companyCreateRequest: CompanyCreateRequest, options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerCreateCompany(companyCreateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetActiveCompanyList(options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetActiveCompanyList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetAllCompanyList(options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetAllCompanyList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<string>} companyIds
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetCompanies(companyIds: Array<string>, options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetCompanies(companyIds, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetCompany(companyId: string, options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetCompany(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyIds
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetCompanyLastPlans(companyIds: string, options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetCompanyLastPlans(companyIds, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetCompanyPlan(companyId: string, options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetCompanyPlan(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} month
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetCompanyPlansAtMonth(month: string, options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetCompanyPlansAtMonth(month, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetCompanyUsers(companyId: string, options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetCompanyUsers(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetInactiveCompanyList(options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetInactiveCompanyList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetPlan(companyId: string, options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetPlan(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetPlanAtLastMonth(companyId: string, options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetPlanAtLastMonth(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetPlanList(options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetPlanList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {CompanyInactivateRequest} companyInactivateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerInactivateCompany(
    companyId: string,
    companyInactivateRequest: CompanyInactivateRequest,
    options?: AxiosRequestConfig
  ) {
    return CompaniesApiFp(this.configuration)
      .companyControllerInactivateCompany(companyId, companyInactivateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {CompanyUpdateRequest} companyUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerUpdateCompany(
    companyId: string,
    companyUpdateRequest: CompanyUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return CompaniesApiFp(this.configuration)
      .companyControllerUpdateCompany(companyId, companyUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {CompanyUpdatePlanRequest} companyUpdatePlanRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerUpdateCompanyPlan(
    companyId: string,
    companyUpdatePlanRequest: CompanyUpdatePlanRequest,
    options?: AxiosRequestConfig
  ) {
    return CompaniesApiFp(this.configuration)
      .companyControllerUpdateCompanyPlan(companyId, companyUpdatePlanRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CompanyUsageApi - axios parameter creator
 * @export
 */
export const CompanyUsageApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetBilling: async (
      companyId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyUsageControllerGetBilling', 'companyId', companyId);
      const localVarPath = `/companies/{companyId}/billing`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetCompanyUsage: async (
      companyId: string,
      since?: string,
      until?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyUsageControllerGetCompanyUsage', 'companyId', companyId);
      const localVarPath = `/companies/{companyId}/usage`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (since !== undefined) {
        localVarQueryParameter['since'] = since;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] = until;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetCompanyUsageAtNow: async (
      companyId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyUsageControllerGetCompanyUsageAtNow', 'companyId', companyId);
      const localVarPath = `/companies/{companyId}/usage/now`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetStatisticsPerForm: async (
      companyId: string,
      since?: string,
      until?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyUsageControllerGetStatisticsPerForm', 'companyId', companyId);
      const localVarPath = `/companies/{companyId}/statistics/form`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (since !== undefined) {
        localVarQueryParameter['since'] = since;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] = until;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetStatisticsPerUser: async (
      companyId: string,
      since?: string,
      until?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyUsageControllerGetStatisticsPerUser', 'companyId', companyId);
      const localVarPath = `/companies/{companyId}/statistics/user`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (since !== undefined) {
        localVarQueryParameter['since'] = since;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] = until;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompanyUsageApi - functional programming interface
 * @export
 */
export const CompanyUsageApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CompanyUsageApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyUsageControllerGetBilling(
      companyId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyUsage>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyUsageControllerGetBilling(companyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyId
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyUsageControllerGetCompanyUsage(
      companyId: string,
      since?: string,
      until?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyUsage>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyUsageControllerGetCompanyUsage(
        companyId,
        since,
        until,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyUsageControllerGetCompanyUsageAtNow(
      companyId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyUsage>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyUsageControllerGetCompanyUsageAtNow(
        companyId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyId
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyUsageControllerGetStatisticsPerForm(
      companyId: string,
      since?: string,
      until?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatisticsPerFormResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyUsageControllerGetStatisticsPerForm(
        companyId,
        since,
        until,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyId
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyUsageControllerGetStatisticsPerUser(
      companyId: string,
      since?: string,
      until?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatisticsPerUserResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyUsageControllerGetStatisticsPerUser(
        companyId,
        since,
        until,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CompanyUsageApi - factory interface
 * @export
 */
export const CompanyUsageApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CompanyUsageApiFp(configuration);
  return {
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetBilling(companyId: string, options?: any): AxiosPromise<CompanyUsage> {
      return localVarFp
        .companyUsageControllerGetBilling(companyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetCompanyUsage(
      companyId: string,
      since?: string,
      until?: string,
      options?: any
    ): AxiosPromise<Array<CompanyUsage>> {
      return localVarFp
        .companyUsageControllerGetCompanyUsage(companyId, since, until, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetCompanyUsageAtNow(companyId: string, options?: any): AxiosPromise<CompanyUsage> {
      return localVarFp
        .companyUsageControllerGetCompanyUsageAtNow(companyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetStatisticsPerForm(
      companyId: string,
      since?: string,
      until?: string,
      options?: any
    ): AxiosPromise<GetStatisticsPerFormResponse> {
      return localVarFp
        .companyUsageControllerGetStatisticsPerForm(companyId, since, until, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetStatisticsPerUser(
      companyId: string,
      since?: string,
      until?: string,
      options?: any
    ): AxiosPromise<GetStatisticsPerUserResponse> {
      return localVarFp
        .companyUsageControllerGetStatisticsPerUser(companyId, since, until, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CompanyUsageApi - object-oriented interface
 * @export
 * @class CompanyUsageApi
 * @extends {BaseAPI}
 */
export class CompanyUsageApi extends BaseAPI {
  /**
   *
   * @param {string} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyUsageApi
   */
  public companyUsageControllerGetBilling(companyId: string, options?: AxiosRequestConfig) {
    return CompanyUsageApiFp(this.configuration)
      .companyUsageControllerGetBilling(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {string} [since]
   * @param {string} [until]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyUsageApi
   */
  public companyUsageControllerGetCompanyUsage(
    companyId: string,
    since?: string,
    until?: string,
    options?: AxiosRequestConfig
  ) {
    return CompanyUsageApiFp(this.configuration)
      .companyUsageControllerGetCompanyUsage(companyId, since, until, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyUsageApi
   */
  public companyUsageControllerGetCompanyUsageAtNow(companyId: string, options?: AxiosRequestConfig) {
    return CompanyUsageApiFp(this.configuration)
      .companyUsageControllerGetCompanyUsageAtNow(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {string} [since]
   * @param {string} [until]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyUsageApi
   */
  public companyUsageControllerGetStatisticsPerForm(
    companyId: string,
    since?: string,
    until?: string,
    options?: AxiosRequestConfig
  ) {
    return CompanyUsageApiFp(this.configuration)
      .companyUsageControllerGetStatisticsPerForm(companyId, since, until, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {string} [since]
   * @param {string} [until]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyUsageApi
   */
  public companyUsageControllerGetStatisticsPerUser(
    companyId: string,
    since?: string,
    until?: string,
    options?: AxiosRequestConfig
  ) {
    return CompanyUsageApiFp(this.configuration)
      .companyUsageControllerGetStatisticsPerUser(companyId, since, until, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetHello: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/hello`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerGetHello(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetHello(options?: any): AxiosPromise<string> {
      return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerGetHello(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .appControllerGetHello(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDividedImagePresignedUrl: async (
      group: string,
      document: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerGetDividedImagePresignedUrl', 'group', group);
      // verify required parameter 'document' is not null or undefined
      assertParamExists('documentControllerGetDividedImagePresignedUrl', 'document', document);
      const localVarPath = `/documents/{group}/{document}/image`
        .replace(`{${'group'}}`, encodeURIComponent(String(group)))
        .replace(`{${'document'}}`, encodeURIComponent(String(document)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDocumentGroupById: async (
      group: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerGetDocumentGroupById', 'group', group);
      const localVarPath = `/documents/{group}`.replace(`{${'group'}}`, encodeURIComponent(String(group)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDocuments: async (group: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerGetDocuments', 'group', group);
      const localVarPath = `/documents/{group}/list`.replace(`{${'group'}}`, encodeURIComponent(String(group)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {DocumentType} [ocrType]
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerSearch: async (
      companyId: string,
      ocrType?: DocumentType,
      since?: string,
      until?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('documentControllerSearch', 'companyId', companyId);
      const localVarPath = `/documents/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (ocrType !== undefined) {
        localVarQueryParameter['ocr_type'] = ocrType;
      }

      if (companyId !== undefined) {
        localVarQueryParameter['company_id'] = companyId;
      }

      if (since !== undefined) {
        localVarQueryParameter['since'] = since;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] = until;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerGetDividedImagePresignedUrl(
      group: string,
      document: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDividedImagePresignedUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerGetDividedImagePresignedUrl(
        group,
        document,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerGetDocumentGroupById(
      group: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentGroupResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerGetDocumentGroupById(group, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerGetDocuments(
      group: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerGetDocuments(group, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} companyId
     * @param {DocumentType} [ocrType]
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerSearch(
      companyId: string,
      ocrType?: DocumentType,
      since?: string,
      until?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentSearchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerSearch(
        companyId,
        ocrType,
        since,
        until,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DocumentApiFp(configuration);
  return {
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDividedImagePresignedUrl(
      group: string,
      document: string,
      options?: any
    ): AxiosPromise<GetDividedImagePresignedUrlResponse> {
      return localVarFp
        .documentControllerGetDividedImagePresignedUrl(group, document, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDocumentGroupById(group: string, options?: any): AxiosPromise<GetDocumentGroupResponse> {
      return localVarFp
        .documentControllerGetDocumentGroupById(group, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDocuments(group: string, options?: any): AxiosPromise<Array<Document>> {
      return localVarFp.documentControllerGetDocuments(group, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {DocumentType} [ocrType]
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerSearch(
      companyId: string,
      ocrType?: DocumentType,
      since?: string,
      until?: string,
      options?: any
    ): AxiosPromise<DocumentSearchResponse> {
      return localVarFp
        .documentControllerSearch(companyId, ocrType, since, until, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
  /**
   *
   * @param {string} group
   * @param {string} document
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerGetDividedImagePresignedUrl(group: string, document: string, options?: AxiosRequestConfig) {
    return DocumentApiFp(this.configuration)
      .documentControllerGetDividedImagePresignedUrl(group, document, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} group
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerGetDocumentGroupById(group: string, options?: AxiosRequestConfig) {
    return DocumentApiFp(this.configuration)
      .documentControllerGetDocumentGroupById(group, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} group
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerGetDocuments(group: string, options?: AxiosRequestConfig) {
    return DocumentApiFp(this.configuration)
      .documentControllerGetDocuments(group, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {DocumentType} [ocrType]
   * @param {string} [since]
   * @param {string} [until]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerSearch(
    companyId: string,
    ocrType?: DocumentType,
    since?: string,
    until?: string,
    options?: AxiosRequestConfig
  ) {
    return DocumentApiFp(this.configuration)
      .documentControllerSearch(companyId, ocrType, since, until, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {Array<any>} [files]
     * @param {string} [subject]
     * @param {string} [text]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emailControllerSendAllUsers: async (
      files?: Array<any>,
      subject?: string,
      text?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/mail/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (files) {
        files.forEach((element) => {
          localVarFormParams.append('files', element as any);
        });
      }

      if (subject !== undefined) {
        localVarFormParams.append('subject', subject as any);
      }

      if (text !== undefined) {
        localVarFormParams.append('text', text as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {Array<any>} [files]
     * @param {string} [subject]
     * @param {string} [text]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async emailControllerSendAllUsers(
      files?: Array<any>,
      subject?: string,
      text?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.emailControllerSendAllUsers(
        files,
        subject,
        text,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = EmailApiFp(configuration);
  return {
    /**
     *
     * @param {Array<any>} [files]
     * @param {string} [subject]
     * @param {string} [text]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emailControllerSendAllUsers(
      files?: Array<any>,
      subject?: string,
      text?: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .emailControllerSendAllUsers(files, subject, text, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
  /**
   *
   * @param {Array<any>} [files]
   * @param {string} [subject]
   * @param {string} [text]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public emailControllerSendAllUsers(
    files?: Array<any>,
    subject?: string,
    text?: string,
    options?: AxiosRequestConfig
  ) {
    return EmailApiFp(this.configuration)
      .emailControllerSendAllUsers(files, subject, text, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsagesApi - axios parameter creator
 * @export
 */
export const UsagesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usageControllerGetUsagesAtMonth: async (month: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'month' is not null or undefined
      assertParamExists('usageControllerGetUsagesAtMonth', 'month', month);
      const localVarPath = `/usages/output`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (month !== undefined) {
        localVarQueryParameter['month'] = month;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsagesApi - functional programming interface
 * @export
 */
export const UsagesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsagesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usageControllerGetUsagesAtMonth(
      month: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyUsageWithPlan>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usageControllerGetUsagesAtMonth(month, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsagesApi - factory interface
 * @export
 */
export const UsagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UsagesApiFp(configuration);
  return {
    /**
     *
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usageControllerGetUsagesAtMonth(month: string, options?: any): AxiosPromise<Array<CompanyUsageWithPlan>> {
      return localVarFp.usageControllerGetUsagesAtMonth(month, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsagesApi - object-oriented interface
 * @export
 * @class UsagesApi
 * @extends {BaseAPI}
 */
export class UsagesApi extends BaseAPI {
  /**
   *
   * @param {string} month
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsagesApi
   */
  public usageControllerGetUsagesAtMonth(month: string, options?: AxiosRequestConfig) {
    return UsagesApiFp(this.configuration)
      .usageControllerGetUsagesAtMonth(month, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerCreateUser: async (
      createUserRequest: CreateUserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUserRequest' is not null or undefined
      assertParamExists('userControllerCreateUser', 'createUserRequest', createUserRequest);
      const localVarPath = `/users/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetCompanyUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('userControllerGetCompanyUser', 'userId', userId);
      const localVarPath = `/users/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetUsers: async (userIds: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userIds' is not null or undefined
      assertParamExists('userControllerGetUsers', 'userIds', userIds);
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userIds !== undefined) {
        localVarQueryParameter['userIds'] = userIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {UserUpdateUserRoleRequest} userUpdateUserRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerUpdateUserRole: async (
      userId: string,
      userUpdateUserRoleRequest: UserUpdateUserRoleRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('userControllerUpdateUserRole', 'userId', userId);
      // verify required parameter 'userUpdateUserRoleRequest' is not null or undefined
      assertParamExists('userControllerUpdateUserRole', 'userUpdateUserRoleRequest', userUpdateUserRoleRequest);
      const localVarPath = `/users/{userId}/role`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userUpdateUserRoleRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerCreateUser(
      createUserRequest: CreateUserRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreateUser(createUserRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerGetCompanyUser(
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetCompanyUser(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} userIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerGetUsers(
      userIds: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUsers(userIds, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} userId
     * @param {UserUpdateUserRoleRequest} userUpdateUserRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerUpdateUserRole(
      userId: string,
      userUpdateUserRoleRequest: UserUpdateUserRoleRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateUserRole(
        userId,
        userUpdateUserRoleRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerCreateUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<CreateUserResponse> {
      return localVarFp
        .userControllerCreateUser(createUserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetCompanyUser(userId: string, options?: any): AxiosPromise<User> {
      return localVarFp.userControllerGetCompanyUser(userId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetUsers(userIds: string, options?: any): AxiosPromise<Array<User>> {
      return localVarFp.userControllerGetUsers(userIds, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userId
     * @param {UserUpdateUserRoleRequest} userUpdateUserRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerUpdateUserRole(
      userId: string,
      userUpdateUserRoleRequest: UserUpdateUserRoleRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .userControllerUpdateUserRole(userId, userUpdateUserRoleRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @param {CreateUserRequest} createUserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public userControllerCreateUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .userControllerCreateUser(createUserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public userControllerGetCompanyUser(userId: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .userControllerGetCompanyUser(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} userIds
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public userControllerGetUsers(userIds: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .userControllerGetUsers(userIds, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} userId
   * @param {UserUpdateUserRoleRequest} userUpdateUserRoleRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public userControllerUpdateUserRole(
    userId: string,
    userUpdateUserRoleRequest: UserUpdateUserRoleRequest,
    options?: AxiosRequestConfig
  ) {
    return UsersApiFp(this.configuration)
      .userControllerUpdateUserRole(userId, userUpdateUserRoleRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
