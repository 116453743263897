import DataLoader from 'dataloader';

import { User } from '../generated-api';
import { usersApi } from './apiClient';

export class UsersDataLoader {
  private loader = new DataLoader<string, User | null>(
    async (keys) => {
      if (keys.length === 0) {
        return [];
      }
      const resultMap: Map<string, User> = new Map();
      const { data } = await usersApi.userControllerGetUsers(keys.join(','));
      data.forEach((value) => resultMap.set(value.user_id, value));

      // keys の順番と結果の順番が一致している必要があるため一度 map に詰めて key を使って取り出す
      return keys.map((key) => resultMap.get(key) ?? null);
    },
    {
      // フロントのページングのサイズが最大100なのでそれぐらい
      maxBatchSize: 100,
    }
  );

  public async getUserById(userId: string): Promise<User | null> {
    return this.loader.load(userId);
  }

  public async getUsersByIds(userIds: string[]): Promise<Array<User | null>> {
    const result = await this.loader.loadMany(userIds);
    return result.filter((v): v is User => !(v instanceof Error));
  }
}

const usersDataLoader = new UsersDataLoader();

export default usersDataLoader;
