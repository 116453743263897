import { Form, Input, InputNumber, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { ReactElement, ReactNode, useMemo } from 'react';
import short from 'short-uuid';
import styled from 'styled-components';

import FormItemWithLabel from '../../../components/Form/FormItemWithLabel';
import { DocumentReceiptEntity, ReceiptDetail } from '../../../generated-api';
import renderTableColumn from './renderTableColumn';
import { OcrResultPageDefinition, ResultPageDocument } from './types';

const ReceiptDetailSection = styled.div`
  padding: 8px;
  margin-bottom: 24px;
  border: 1px solid #f0f0f0;
  display: grid;
  grid-gap: 8px;
  align-items: center;
  overflow: auto;
  grid-auto-rows: 40px;
`;
const ReceiptDetailLabel = styled.div`
  margin-bottom: 12px;
`;
const RowHeaderLabel = styled.div`
  font-weight: bold;
  padding-left: 4px;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(80px, 1fr)) repeat(2, minmax(100px, 1fr));
  grid-gap: 8px;
  align-items: baseline;
`;
const InputNumberStyle = styled(InputNumber)`
  width: 100%;
`;

type FormProps = {
  entity: DocumentReceiptEntity;
};
export function ReceiptFormInput({ entity }: FormProps): ReactElement {
  return (
    <>
      <FormItemWithLabel label="支払い日付">
        <Input disabled defaultValue={entity.payment_date} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払い先">
        <Input disabled defaultValue={entity.payee ? entity.payee[0] : '-'} />
      </FormItemWithLabel>
      <FormItemWithLabel label="事業者番号">
        <Input disabled defaultValue={entity.business_number} />
      </FormItemWithLabel>
      <ReceiptDetailForm entity={entity} />
    </>
  );
}
type ReceiptDetailFormProps = {
  entity: DocumentReceiptEntity;
};
function ReceiptDetailForm({ entity }: ReceiptDetailFormProps): ReactElement {
  const details = useMemo(() => {
    return (
      entity.receipt_details?.reduce<Record<string, ReceiptDetail>>((accumulator, current) => {
        accumulator[short.generate()] = current;
        return accumulator;
      }, {}) ?? {}
    );
  }, [entity.receipt_details]);

  const rowIds = Object.keys(details);

  const defaultValue = useMemo(() => {
    return {
      tax_percent: undefined,
      amount_without_tax: 0,
      amount_with_tax: 0,
      tax_amount: 0,
      account: '-',
    };
  }, []);

  if (rowIds.length === 0) {
    const rowId = short.generate();
    rowIds.push(rowId);
    details[rowId] = defaultValue;
  }

  return (
    <>
      <ReceiptDetailLabel>明細</ReceiptDetailLabel>
      <ReceiptDetailSection>
        <Row>
          <RowHeaderLabel>税率</RowHeaderLabel>
          <RowHeaderLabel>税抜き金額</RowHeaderLabel>
          <RowHeaderLabel>税額</RowHeaderLabel>
          <RowHeaderLabel>税込み金額</RowHeaderLabel>
          <RowHeaderLabel>勘定科目</RowHeaderLabel>
        </Row>
        {rowIds.map((id) => (
          <ReceiptDetailRow key={id} detail={details[id] ?? defaultValue} rowId={id} />
        ))}
      </ReceiptDetailSection>
    </>
  );
}

type ReceiptDetailRowProps = {
  detail: ReceiptDetail;
  rowId: string;
};
function ReceiptDetailRow({ detail, rowId }: ReceiptDetailRowProps): ReactElement {
  return (
    <Row>
      <Form.Item initialValue={detail.tax_percent} name={['receipt_detail', rowId, 'tax_percent']}>
        <InputNumberStyle disabled />
      </Form.Item>
      <Form.Item initialValue={detail.amount_without_tax} name={['receipt_detail', rowId, 'amount_without_tax']}>
        <InputNumberStyle disabled />
      </Form.Item>
      <Form.Item initialValue={detail.tax_amount} name={['receipt_detail', rowId, 'tax_amount']}>
        <InputNumberStyle disabled />
      </Form.Item>
      <Form.Item initialValue={detail.amount_with_tax} name={['receipt_detail', rowId, 'amount_with_tax']}>
        <InputNumberStyle disabled />
      </Form.Item>
      <Form.Item initialValue={detail.account ?? '-'} name={['receipt_detail', rowId, 'account']}>
        <Input disabled type="text" />
      </Form.Item>
    </Row>
  );
}

export function ReceiptTable({ receipt }: { receipt: DocumentReceiptEntity }): ReactElement {
  const paymentDate = receipt.payment_date ?? '-';
  const payee = receipt.payee ?? ['-'];
  const receiptDetails = receipt.receipt_details;

  const data: Record<string, string | number>[] = receiptDetails
    ? receiptDetails.map((detail) => {
        return {
          payment_date: paymentDate,
          payee: payee[0],
          tax_percent: detail.tax_percent ?? 10,
          amount_without_tax: detail.amount_without_tax ?? 0,
          tax_amount: detail.tax_amount ?? 0,
          amount_with_tax: detail.amount_with_tax ?? 0,
          account: detail.account ?? '-',
        };
      })
    : [
        // detailがない場合は、デフォルトで10%の欄に表示する
        {
          payment_date: paymentDate,
          payee: payee[0],
          tax_percent: 10,
          amount_without_tax: 0,
          tax_amount: 0,
          amount_with_tax: 0,
          account: '-',
        },
      ];

  return (
    <Table dataSource={data} pagination={false}>
      <Column key="payment_date" dataIndex="payment_date" title="支払い日付" />
      <Column key="payee" dataIndex="payee" title="支払先" />
      <Column key="tax_percent" dataIndex="tax_percent" title="税率" />
      <Column key="amount_without_tax" dataIndex="amount_without_tax" title="税抜き金額" />
      <Column key="tax_amount" dataIndex="tax_amount" title="税額" />
      <Column key="amount_with_tax" dataIndex="amount_with_tax" title="税込み金額" />
      <Column key="account" dataIndex="account" title="勘定科目" />
    </Table>
  );
}

function expandReceiptTable(record: ResultPageDocument<DocumentReceiptEntity>): ReactNode {
  if (record.error) {
    return;
  }
  return <ReceiptTable receipt={record.latest_revision} />;
}

type PayeeColumnProps = {
  record: DocumentReceiptEntity;
};
function PayeeColumn({ record }: PayeeColumnProps) {
  if (record.payee) {
    return <>{record.payee[0]}</>;
  }
  return <>-</>;
}

const receiptResultPageDefinition: OcrResultPageDefinition<DocumentReceiptEntity> = {
  name: '(ベータ版)領収書',
  tableColumns: [
    { dataIndex: ['latest_revision', 'payment_date'], title: '支払い日付', render: renderTableColumn },
    {
      dataIndex: ['latest_revision'],
      title: '支払先',
      render: (record: DocumentReceiptEntity) => <PayeeColumn record={record} />,
    },
    {
      dataIndex: ['latest_revision', 'business_number'],
      title: '事業者番号',
      render: renderTableColumn,
    },
  ],
  tableExpandable: {
    expandedRowRender: expandReceiptTable,
  },
  formComponent: ReceiptFormInput,
};

export default receiptResultPageDefinition;
