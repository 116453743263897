import { Alert, Select, Tabs } from 'antd';
import { add, format, lastDayOfMonth, startOfMonth, sub } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import styled from 'styled-components';

import { companiesApi, companyUsageApi } from '../../api/apiClient';
import useAPIData from '../../hooks/useAPIData';
import CompanyUsageTable from './CompanyUsageTable';
import FeeTable from './FeeTable';
import StatsFormTable from './StatsFormTable';
import StatsUserTable from './StatsUserTable';

const UsagePage = () => {
  const [companyId, setCompanyId] = useState<string | undefined>();
  const [companySelected, setCompanySelected] = useState<boolean>(false);

  const { data: companyListData, loading: companyListLoading } = useAPIData(async () => {
    const { data: companyList } = await companiesApi.companyControllerGetAllCompanyList();
    const companyOptions = companyList.map((element) => {
      return {
        label: element.company_name,
        value: element.company_id,
      };
    });
    return { companyOptions };
  });

  const now = useMemo(() => new Date(), []);
  const firstDayLastMonth = startOfMonth(sub(now, { months: 1 }));
  const lastDayLastMonth = lastDayOfMonth(firstDayLastMonth);
  const firstDayThisMonth = startOfMonth(now);
  const nextMonth = add(now, { months: 1 });
  const oneYearBefore = sub(firstDayThisMonth, { years: 1 });
  const since = format(oneYearBefore, 'yyyy-MM');
  // 利用料金別はSortKeyが'yyyy-MM'なので'2022-07'で7月分まで取得される
  // statsは'yyyy-MM-XXXXXX'なので'2022-08'で7月分まで取得される
  const companyUsageUntil = format(now, 'yyyy-MM');
  const statsUntil = format(nextMonth, 'yyyy-MM');

  const onCompanyChange = useCallback((companyId: string) => {
    setCompanyId(companyId);
  }, []);

  const { value: companyUsages } = useAsync(async () => {
    if (companyId) {
      setCompanySelected(true);
      const { data: usages } = await companyUsageApi.companyUsageControllerGetCompanyUsage(
        companyId,
        since,
        companyUsageUntil
      );
      const { data: usageAtNow } = await companyUsageApi.companyUsageControllerGetCompanyUsageAtNow(companyId);
      const filterdUsages = usages.filter((usage) => usage.month != usageAtNow.month);
      const usageWithNow = [...filterdUsages, usageAtNow];
      return usageWithNow.sort((a, b) => {
        return a.month > b.month ? -1 : 1;
      });
    }
  }, [companyId]);

  const lastMonthUsage = useMemo(() => {
    const lastMonth = format(sub(now, { months: 1 }), 'yyyy-MM');
    return companyUsages?.find((companyUsage) => companyUsage.month === lastMonth);
  }, [companyUsages, now]);

  const { value: statisticsForm } = useAsync(async () => {
    if (companyId) {
      const { data } = await companyUsageApi.companyUsageControllerGetStatisticsPerForm(companyId, since, statsUntil);
      return data.statistics;
    }
  }, [companyId]);

  const { value: statisticsUser } = useAsync(async () => {
    if (companyId) {
      const { data: statisticsUser } = await companyUsageApi.companyUsageControllerGetStatisticsPerUser(
        companyId,
        since,
        statsUntil
      );
      return statisticsUser.statistics;
    }
  }, [companyId]);

  const { value: planForBillingDetail } = useAsync(async () => {
    if (companyId) {
      const { data } = await companiesApi.companyControllerGetPlanAtLastMonth(companyId);
      return data;
    }
  }, [companyId]);

  const hasPlan = planForBillingDetail != null;

  const { TabPane } = Tabs;

  return (
    <Container>
      <PageHeading>利用履歴 (管理者画面)</PageHeading>
      <Content>
        <SubTitle>組織名</SubTitle>
        <Select
          showSearch
          dropdownStyle={{ minWidth: '50%' }}
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          loading={companyListLoading}
          options={companyListData?.companyOptions}
          placeholder="会社を選択してください"
          onSelect={onCompanyChange}
        />
        <SummaryContainer>
          <Title>{format(now, 'yyyy年M月')}ご請求額</Title>
          <SubTitle>
            {format(firstDayLastMonth, 'yyyy/M/d')}～{format(lastDayLastMonth, 'yyyy/M/d')} 使用分
          </SubTitle>
          <Notes> ※使用量にはβ版機能の使用枚数も含まれます</Notes>
          <UsageItemContainer>
            <UsageItem>
              <UsageItemName>請求額</UsageItemName>
              <UsageItemValue>{lastMonthUsage?.bill_with_tax.toLocaleString() ?? '-'}</UsageItemValue>
              <UsageItemUnit>円</UsageItemUnit>
            </UsageItem>
            <UsageItem>
              <UsageItemName>使用量</UsageItemName>
              <UsageItemValue>{lastMonthUsage?.usage ?? '-'}</UsageItemValue>
              <UsageItemUnit>枚</UsageItemUnit>
            </UsageItem>
          </UsageItemContainer>
          {companySelected && !hasPlan && <Alert message="プランが見つかりません" type="error" />}
          {hasPlan && planForBillingDetail && <FeeSummaryTable plan={planForBillingDetail} usage={lastMonthUsage} />}
        </SummaryContainer>

        <Title>利用履歴の詳細</Title>
        <TableContainer>
          <Tabs defaultActiveKey="1" size="large">
            <TabPane key="1" tab="利用料金別">
              <CompanyUsageTable companyUsages={companyUsages} />
            </TabPane>
            <TabPane key="2" tab="帳票別">
              <StatsFormTable usages={statisticsForm} />
            </TabPane>
            <TabPane key="3" tab="実施者別">
              <StatsUserTable statisticsUser={statisticsUser} />
            </TabPane>
          </Tabs>
        </TableContainer>
      </Content>
    </Container>
  );
};

export default UsagePage;

const Container = styled.div`
  width: 80%;
  margin: 24px;
`;

const PageHeading = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: #5a89d0;
  border-bottom: solid 2px #5a89d0;
`;

const Content = styled.div`
  max-width: 1000px;
  width: 90%;
  margin-top: 24px;
  margin-left: 24px;
`;

const SummaryContainer = styled.div`
  border: solid 1px;
  border-radius: 10px;
  width: 60%;
  margin-top: 36px;
  margin-bottom: 36px;
  padding: 24px;
`;

const FeeSummaryTable = styled(FeeTable)`
  padding-top: 24px;
  width: 300px;
`;

const Title = styled.h3`
  font-size: 24px;
  color: #868686;
`;

const SubTitle = styled.div`
  font-size: 20px;
`;

const Notes = styled.div``;

const UsageItemContainer = styled.div`
  margin: 12px auto;
  width: 30%;
`;

const UsageItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  width: 100%;
  font-size: 12px;
`;

const UsageItemName = styled.span`
  margin-right: auto;
`;

const UsageItemValue = styled.span`
  font-size: 30px;
`;

const UsageItemUnit = styled.span`
  margin-left: 10px;
`;

const TableContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
