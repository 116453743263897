import { ReactNode } from 'react';
import styled from 'styled-components';

import { ContentContainer } from './ContentContainer';

type Props = {
  heading: string;
  children: ReactNode;
};

const PageLayout = ({ children, heading }: Props) => {
  return (
    <Container>
      <Heading>{heading}</Heading>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};

export default PageLayout;

const Container = styled.div`
  width: 80%;
  padding: 24px;
`;

const Heading = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: #5a89d0;
  border-bottom: solid 2px #5a89d0;
  margin-bottom: 32px;
`;
