import { Card, Tabs } from 'antd';
import { ReactNode, useMemo, useState } from 'react';

import {
  DocumentDualHorizontalReceiptEntity,
  DocumentDualVerticalReceiptEntity,
  DocumentReceiptEntity,
} from '../../../generated-api';
import { ReceiptFormInput, ReceiptTable } from './receiptResultPageDefinition';
import { FormComponentProps, OcrResultPageDefinition, ResultPageDocument } from './types';

const { TabPane } = Tabs;
type DocumentDualReceiptEntity = DocumentDualHorizontalReceiptEntity | DocumentDualVerticalReceiptEntity;

function DualReceiptFormComponent({ entity }: FormComponentProps<DocumentDualReceiptEntity>) {
  const [tab, setTab] = useState('0');
  const formEntities: DocumentReceiptEntity[] = useMemo(
    () =>
      entity.receipts.map((receipt) => ({
        type: receipt.type,
        payment_date: receipt.payment_date,
        payee: receipt.payee,
        business_number: receipt.business_number,
        receipt_details: receipt.receipt_details,
      })),
    [entity.receipts]
  );

  return (
    <>
      <Tabs defaultActiveKey={tab} onChange={setTab}>
        {formEntities.map((form, index) => (
          <TabPane key={index} forceRender tab={`領収書${index + 1}`} tabKey={index.toString()}>
            <ReceiptFormInput entity={form} />
          </TabPane>
        ))}
      </Tabs>
    </>
  );
}

function expandDualReceiptTable(record: ResultPageDocument<DocumentDualReceiptEntity>): ReactNode {
  if (record.error) {
    return;
  }
  return (
    <>
      {record.latest_revision.receipts.map((receipt, receiptIndex) => (
        <Card key={receiptIndex}>
          <ReceiptTable receipt={receipt} />
        </Card>
      ))}
    </>
  );
}

function dualReceiptResultPageDefinition<T extends DocumentDualReceiptEntity>(
  type: T['type'],
  name: string
): OcrResultPageDefinition<T> {
  return {
    name: name,
    tableColumns: [],
    tableExpandable: {
      expandedRowRender: expandDualReceiptTable,
    },
    formComponent: DualReceiptFormComponent,
  };
}

export const dualHorizontalReceiptResultPageDefinition =
  dualReceiptResultPageDefinition<DocumentDualHorizontalReceiptEntity>('dual_horizontal_receipt', '(ベータ版)領収書');

export const dualVerticalReceiptResultPageDefinition =
  dualReceiptResultPageDefinition<DocumentDualVerticalReceiptEntity>('dual_vertical_receipt', '(ベータ版)領収書');
