import { ReactElement } from 'react';
import styled from 'styled-components';

import { SuccessIcon } from '../../components/Icons/StatusIcon';
import LoadingDot from '../../components/Loading/LoadingDot';
import LoadingIcon from '../../components/Loading/LoadingIcon';

interface Props {
  completed: boolean;
}

const Status = styled.div`
  margin-top: 20px;
  height: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export default function DocumentStatus(props: Props): ReactElement {
  const { completed } = props;
  if (completed) {
    return (
      <Status>
        <SuccessIcon /> ファイルの読み取りが完了しました。
      </Status>
    );
  } else {
    return (
      <Status>
        <LoadingIcon style={{ color: '#ccc' }} /> ファイルを読み取っています <LoadingDot />
      </Status>
    );
  }
}
