import { Button, Modal, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAsyncRetry } from 'react-use';
import styled from 'styled-components';

import { adminApi } from '../../api/apiClient';
import LoadingIcon from '../../components/Loading/LoadingIcon';
import { useMeStore } from '../../store/useMeStore';
import { notifyError, notifySuccess } from '../../utils/notification';

type AdminUser = {
  user_id: string;
  name: string;
  email: string;
};

type DeleteButtonProps = {
  userId: string;
  userName: string;
  reload: () => void;
};

const tableCols = (reload: () => void): ColumnsType<AdminUser> => [
  {
    title: 'ユーザー名',
    dataIndex: 'name',
  },
  {
    title: 'メールアドレス',
    dataIndex: 'email',
  },
  {
    render: (record: AdminUser) => (
      <Space size="middle">
        <EditButton userId={record.user_id} />
        <DeleteButton reload={reload} userId={record.user_id} userName={record.name} />
      </Space>
    ),
  },
];

const EditButton = ({ userId }: { userId: string }) => {
  const history = useHistory();
  const onClick = useCallback(() => {
    history.push(`/users/${userId}`);
  }, [history, userId]);
  return (
    <Button type="primary" onClick={onClick}>
      編集
    </Button>
  );
};

const DeleteButton = ({ reload, userId, userName }: DeleteButtonProps) => {
  const me = useMeStore();
  const onClick = useCallback(async () => {
    const onClickDelete = async () => {
      try {
        const { data: user } = await adminApi.adminUserControllerDeleteAdminUser(userId);
        notifySuccess(`ユーザ「${user.user_id}」｢${user.name}｣を削除しました`);
        reload();
      } catch {
        notifyError('削除に失敗しました。');
      }
    };
    Modal.confirm({
      title: `ユーザ｢${userName}｣を削除しますか？`,
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => onClickDelete(),
    });
  }, [userId, userName, reload]);
  return (
    <Button danger disabled={me.userId == userId} type="primary" onClick={onClick}>
      ユーザー削除
    </Button>
  );
};

const AdminUserList = () => {
  const history = useHistory();

  const { retry: reload, value: adminUsers } = useAsyncRetry(async () => {
    const { data } = await adminApi.adminUserControllerGetAllAdminUser();
    return data;
  }, [history]);

  const createUser = useCallback(() => {
    history.push('/users/create/');
  }, [history]);

  const columns = tableCols(reload);

  return (
    <Container>
      <Heading>
        <PageTitle>管理者ユーザー一覧</PageTitle>
        <Button type="primary" onClick={createUser}>
          新規作成
        </Button>
      </Heading>
      <Content>
        {!adminUsers && (
          <LoadingIconWrapper>
            <LoadingIcon />
          </LoadingIconWrapper>
        )}
        {adminUsers && <Table columns={columns} dataSource={adminUsers} rowKey="user_id" />}
      </Content>
    </Container>
  );
};

export default AdminUserList;

const Container = styled.div`
  width: 80%;
  padding: 24px;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #5a89d0;
  padding-right: 40px;
`;

const PageTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: #5a89d0;
`;

const Content = styled.div`
  width: 90%;
  margin-left: 24px;
  margin-top: 36px;
`;

const LoadingIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
