import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import styled from 'styled-components';

import { companiesApi } from '../../api/apiClient';
import InactiveFrom from '../../components/Date/InactiveFrom';
import { Company } from '../../generated-api';

const tableColumns = (edit: (key: string) => void): ColumnsType<Company> => {
  return [
    { title: '組織名', dataIndex: 'company_name' },
    { title: '顧客番号', dataIndex: 'company_rpa_id' },
    {
      title: '退会日',
      dataIndex: 'inactive_from',
      render: (data: string) => <InactiveFrom value={data} />,
    },
    {
      title: '退会処理日',
      dataIndex: 'inactive_process_at',
      render: (data: string) => <InactiveFrom value={data} />,
    },
    {
      render: (record: Company) => {
        return (
          <Button type="primary" onClick={() => edit(record.company_id)}>
            編集
          </Button>
        );
      },
    },
  ];
};

const InactivatedCompanyList = () => {
  const { value: inactiveCompanyRecords } = useAsync(async () => {
    const { data } = await companiesApi.companyControllerGetInactiveCompanyList();
    return data;
  }, []);
  const history = useHistory();
  const companyList = useCallback(() => {
    history.push('/');
  }, [history]);

  const edit = useCallback(
    (companyId) => {
      history.push(`/inactive/${companyId}`);
    },
    [history]
  );

  const columns = useMemo(() => {
    if (inactiveCompanyRecords) {
      return tableColumns(edit);
    } else {
      return undefined;
    }
  }, [inactiveCompanyRecords, edit]);

  return (
    <Container>
      <Heading>
        <PageTitle>退会した組織</PageTitle>
        <Button type="primary" onClick={companyList}>
          利用組織一覧
        </Button>
      </Heading>
      <Content>
        <Table columns={columns} dataSource={inactiveCompanyRecords} rowKey="company_id" />
      </Content>
    </Container>
  );
};

export default InactivatedCompanyList;

const Container = styled.div`
  width: 80%;
  padding: 24px;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #5a89d0;
  padding-right: 40px;
`;

const PageTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: #5a89d0;
`;

const Content = styled.div`
  width: 90%;
  margin-left: 24px;
  margin-top: 36px;
`;
