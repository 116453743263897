import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAsyncRetry } from 'react-use';
import styled from 'styled-components';

import { companiesApi, usersApi } from '../../api/apiClient';
import { formatInactiveFrom, formatInactiveProcessAt } from '../../components/Date/InactiveFrom';
import { Company, Plan, User, UserRoleEnum } from '../../generated-api';
import useAPIData from '../../hooks/useAPIData';
import { notifyError, notifySuccess } from '../../utils/notification';
import CompanyPlansHistory from './CompanyPlansHistory/CompanyPlansHistory';
import CreateUserModal, { CreateUserValue } from './CreateUserModal';
import InactivateCompanyModal, { InactivateCompanyModalValue } from './InactivateCompanyModal';

type CompanyUpdateForm = {
  company_name: string;
  company_rpa_id: string;
  plan_id: string;
  address: string;
  representative_user_id: string;
};

type param = {
  id: string;
};

const CompanyUpdate = () => {
  const history = useHistory();
  const param: param = useParams();
  const companyId = param.id;

  const [showInactivateModal, setShowInactivateModal] = useState(false);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);

  const [form] = useForm<CompanyUpdateForm>();

  const plans = useAPIData(async () => {
    const { data } = await companiesApi.companyControllerGetPlanList();

    return data.reduce<Record<string, Plan>>((acc, curr) => ({ ...acc, [curr.plan_id]: curr }), {});
  }).data;

  const company = useAPIData(async () => {
    const { data } = await companiesApi.companyControllerGetCompany(companyId);
    return data;
  }).data as Company | null;

  const companyPlanHistories = useAPIData(async () => {
    const { data } = await companiesApi.companyControllerGetCompanyPlan(companyId);
    // サーバー側で日時に対して降順でソート済み
    return data.companyPlans;
  }).data;

  const latestCompanyPlan = useMemo(() => companyPlanHistories?.[0] ?? null, [companyPlanHistories]);

  const [representativeUser, setRepresentativeUser] = useState<User | null>(null);
  useEffect(() => {
    (async () => {
      if (company) {
        try {
          const { data } = await usersApi.userControllerGetCompanyUser(company.representative_user_id);
          setRepresentativeUser(data);
        } catch {
          const unknownUser: User = {
            company_id: company.company_id,
            company_name: company.company_name,
            email: '-',
            name: '不明なユーザ',
            password_change_required: false,
            role: 'admin',
            user_id: 'unknown-user',
          };
          setRepresentativeUser(unknownUser);
        }
      }
    })();
  }, [company]);

  const { retry: refetchUsers, value: companyUsers } = useAsyncRetry(async () => {
    if (company) {
      const { data } = await companiesApi.companyControllerGetCompanyUsers(companyId);
      return data;
    }
  }, [company, companyId]);

  const onSubmitInactivate = async ({ date }: InactivateCompanyModalValue) => {
    try {
      await companiesApi.companyControllerInactivateCompany(companyId, {
        // サーバー側も 0 origin で受け取る
        inactive_from_month_index: date.getMonth(),
        inactive_from_year: date.getFullYear(),
      });
      notifySuccess('退会処理が完了しました。');
      history.push('/');
    } catch {
      notifyError('退会処理に失敗しました。');
    }
  };

  const onSubmitCreateUser = async (value: CreateUserValue) => {
    try {
      await usersApi.userControllerCreateUser({
        company_id: companyId,
        email: value.email,
        name: value.name,
        role: value.role,
      });
      notifySuccess('ユーザを新規登録しました。');
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data?.reason === 'USER_ALREADY_EXISTS') {
        notifyError('そのメールアドレスのユーザは既に存在しています。');
      } else {
        notifyError('ユーザ登録に失敗しました。');
      }
      return;
    }
    refetchUsers();
    setShowCreateUserModal(false);
  };

  const updateCompany = useCallback(
    async (formData: CompanyUpdateForm) => {
      try {
        // 各項目更新があればkick api
        if (
          company &&
          representativeUser &&
          (formData.company_name !== company.company_name ||
            formData.company_rpa_id !== company.company_rpa_id ||
            formData.address !== company.address ||
            formData.representative_user_id !== representativeUser.user_id)
        ) {
          await companiesApi.companyControllerUpdateCompany(companyId, {
            company_name: formData.company_name,
            company_rpa_id: formData.company_rpa_id,
            address: formData.address,
            representative_user_id: formData.representative_user_id,
          });
        }
        if (formData.plan_id !== latestCompanyPlan?.plan_id) {
          await companiesApi.companyControllerUpdateCompanyPlan(companyId, {
            plan_id: formData.plan_id,
          });
        }
        if (
          representativeUser &&
          formData.representative_user_id !== representativeUser.user_id &&
          formData.representative_user_id !== 'unknown-user'
        ) {
          await usersApi.userControllerUpdateUserRole(formData.representative_user_id, {
            role: UserRoleEnum.Admin,
          });
        }
        notifySuccess('会社情報を更新しました。');
        history.push('/');
      } catch (error) {
        notifyError('会社情報の更新に失敗しました。');
      }
    },
    [company, representativeUser, latestCompanyPlan?.plan_id, history, companyId]
  );

  return (
    <Container>
      <Heading>
        <PageTitle>組織編集</PageTitle>
        <Buttons>
          <Button type="primary" onClick={form.submit}>
            登録する
          </Button>
          <Button danger={true} type="primary" onClick={() => history.push('/')}>
            キャンセル
          </Button>
          <Button danger type="primary" onClick={() => setShowInactivateModal(true)}>
            退会処理
          </Button>
        </Buttons>
      </Heading>
      <Content>
        {!company || !companyPlanHistories || !representativeUser ? (
          <CenteredLoadingOutlined />
        ) : (
          <Form
            form={form}
            labelAlign="left"
            labelCol={{ span: 8 }}
            labelWrap={true}
            layout="horizontal"
            onFinish={updateCompany}>
            <Form.Item
              initialValue={company.company_name}
              label="組織名"
              name="company_name"
              rules={[{ required: true, message: '会社名を入力してください。' }]}>
              <TextInput type="text" />
            </Form.Item>
            <Form.Item initialValue={company.company_rpa_id} label="顧客番号" name="company_rpa_id">
              <TextInput type="text" />
            </Form.Item>
            <Form.Item
              initialValue={latestCompanyPlan?.plan_id}
              label="利用プラン"
              name="plan_id"
              rules={[{ required: true, message: 'プランを選択してください。' }]}>
              <Select style={{ width: '320px' }}>
                {plans &&
                  Object.values(plans).map((plan) => {
                    return (
                      <Select.Option key={plan.plan_id} value={plan.plan_id}>
                        {plan.plan_name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item initialValue={company.address} label="住所" name="address">
              <TextInput type="text" />
            </Form.Item>
            <Form.Item
              initialValue={representativeUser.user_id}
              label="担当者名"
              name="representative_user_id"
              rules={[{ required: true, message: '担当者名を選択してください。' }]}>
              <Select style={{ width: '320px' }}>
                {companyUsers?.map((user) => {
                  return (
                    <Select.Option key={user.user_id} value={user.user_id}>
                      {user.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="担当者メールアドレス">
              <TextInput
                disabled
                defaultValue={representativeUser?.email ?? '-'}
                title="メールアドレスの変更は、管理者サイトで行うことは出来ません。"
              />
            </Form.Item>
            <Form.Item colon={false} label=" ">
              <Button icon={<PlusOutlined />} type="primary" onClick={() => setShowCreateUserModal(true)}>
                新規ユーザ登録
              </Button>
            </Form.Item>
            {company.inactive_from && (
              <Form.Item label="退会日">
                <TextInput
                  disabled
                  title="退会日の変更は「退会処理」から行ってください"
                  type="text"
                  value={formatInactiveFrom(company.inactive_from)}
                />
              </Form.Item>
            )}
            {company.inactive_process_at && (
              <Form.Item label="退会処理日">
                <TextInput disabled type="text" value={formatInactiveProcessAt(company.inactive_process_at)} />
              </Form.Item>
            )}
          </Form>
        )}
      </Content>
      <TableContainer>
        <CompanyPlansHistory companyPlans={companyPlanHistories} plans={plans} />
      </TableContainer>

      {showCreateUserModal && (
        <CreateUserModal onCancel={() => setShowCreateUserModal(false)} onSubmit={onSubmitCreateUser} />
      )}
      {showInactivateModal && (
        <InactivateCompanyModal
          companyName={company?.company_name ?? ''}
          onCancel={() => setShowInactivateModal(false)}
          onSubmit={onSubmitInactivate}
        />
      )}
    </Container>
  );
};

export default CompanyUpdate;

const Container = styled.div`
  width: 80%;
  padding: 24px;
  min-width: 730px;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #5a89d0;
  padding-right: 40px;
`;

const PageTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: #5a89d0;
`;

const Content = styled.div`
  border: 1px solid #707070;
  border-radius: 15px;
  width: 90%;
  margin-left: 24px;
  margin-top: 36px;
  padding: 40px;
`;

const CenteredLoadingOutlined = styled(LoadingOutlined)`
  display: flex;
  justify-content: center;
`;

const Buttons = styled(Space)`
  align-items: unset;
`;

const TextInput = styled(Input)`
  width: 320px;
`;

const TableContainer = styled.div`
  margin: 10px 20px;
`;
